@import "src/styles/minix";

.tip-modal {

  .semi-modal {
    .semi-modal-content {
      padding-top: 19px;
      padding-bottom: 19px;
    }

    .modal-tip-content {
      width: 100%;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 111px;
      }

      .title {
        margin-top: 31px;
        color: var(--primary-text-color);
        font-size: var(--semi-font-size-small);
      }

      .desc {
        margin-top: 11px;
        color: var(--description-text-color);
        font-size: var(--semi-font-size-extra-small);
        margin-bottom: 28px;
      }

      .semi-button {
        @include font(500);
        width: 113px;
        height: var(--input-height-small);
        margin: 0 10px 0;
        font-size: var(--semi-font-size-small);
      }
    }
  }

}
