.table-navigation {
  position: relative;

  .semi-portal-inner {
    width: calc(100% - 48px);
  }

  .table-type-tabs {
    & > .semi-tabs-content {
      height: 100%;

      .semi-tabs-pane {
        height: 100%;

        .semi-tabs-pane-motion-overlay {
          height: 100%;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
            width: 0;
          }
        }
      }
    }
  }
}
