.integrations-container {
  background-color: var(--v2-color-grey100);

  .header {
    .btn-docs {
      .semi-typography-link-text {
        color: var(--v2-color-grey500) !important;
        font-weight: 400;
      }

      .semi-typography-icon {
        color: var(--v2-color-grey500);
        display: inline-block;
        vertical-align: text-top;
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    .btn-create-integration {
      svg path {
        fill: #ffffff;
      }
    }
  }

  .integrations-table-wrapper {
    .semi-table-tbody {
      .semi-table-row {
        cursor: pointer;
      }
    }
  }

  .integration-selector-wrapper {
    padding: 24px;
  }
}
