@import 'src/styles/minix';

.semi-portal {
  .semi-popover-wrapper {
    border-radius: 9px;
    box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.05);
  }
}

.semi-select {
  transition: background-color 0.35s ease-in-out;

  &.semi-select-open,
  &.semi-select-focus,
  &:hover,
  &:active,
  &:focus {
    border: none;
  }

  .semi-select-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    @include font(400);
    font-size: var(--semi-font-size-small);

    .semi-select-selection-text {
      user-select: none;
    }
    border-right: 1px solid var(--semi-color-border);
    padding-right: 8px;
  }
  .semi-select-arrow {
    width: 34px !important;
  }
}

div[id^='semi-select'] {
  &.center {
    .semi-select-option-list {
      .semi-select-option {
        justify-content: center;
        padding-left: 0;
      }
    }
  }

  .semi-select-option-list {
    border: 1px solid var(--semi-color-border);
    border-radius: 4px;

    .semi-select-option {
      @include font(400);
      transition: background-color 0.1s ease-in-out;

      margin: 3px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 12px;
      border-radius: var(--semi-border-radius-extra-small);
      font-size: var(--semi-font-size-small);
      line-height: 16px;

      .semi-select-option-text {
        padding: 0;
      }

      .semi-select-option-icon {
        display: none;
      }

      &.semi-select-option-focused {
        @include font(500);
      }
    }
  }
}

.select-icon {
  &.semi-select {
    height: var(--height-control-default);
    width: 100%;
    font-size: var(--semi-font-size-small);

    .semi-select-selection {
      justify-content: flex-start;
      margin-left: 0;

      .semi-select-selection-text {
        padding-left: 19px;
      }
    }
  }

  &.placeholder {
    .semi-select-selection-text {
      color: var(--description-text-color);
    }
  }

  .semi-select-selection-text {
    .placeholder {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--description-text-color);
    }

    &.semi-select-selection-text-hide {
      display: none;
    }
  }

  .semi-select-selection-text,
  .semi-select-option-list .semi-select-option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 13px;
  }
}

.form-search-select {
  .semi-select-input {
    display: none;
  }

  .semi-select-selection-text.semi-select-selection-text-hide {
    display: flex;
  }

  &-dropdown {
    .select-search-input-wrapper {
      padding: 6.5px 9px 6.5px;
      width: 100%;
      box-sizing: border-box;

      .select-search-input {
        height: 37px;
        background-color: #fff;
        border: 1px solid var(--semi-color-fill-0);

        input {
          line-height: 37px;
          font-size: var(--semi-font-size-extra-small);
        }

        .icon {
          display: none;
        }

        &:not(.has-icon) {
          padding-left: 9px;
        }

        &.has-icon {
          .icon {
            width: 15px;
            height: 15px;
            margin: 9px;
          }
        }
      }
    }
  }
}
