.integration-configuration-container {
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  .configuration-card {
    border: 1px solid #e3e7ec;
    background: #e3e7ec;
    border-radius: 8px;
    height: 100%;
    width: 100%;

    .semi-card-body {
      background-color: white;
      width: 100%;
      height: 100%;
      padding: 0;

      display: flex;

      .left-wrapper {
        border-right: 1px solid #e3e7ec;
        padding: 12px 24px;
        width: 50%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .form-input-wrapper {
          flex-grow: 1;

          .form-item-group {
            .semi-form-field {
              padding-top: 12px;
              padding-bottom: 12px;

              .semi-form-field-label {
                margin-bottom: 5px;
              }

              .form-item {
                &.semi-select {
                  width: 100%;

                  .semi-select-selection {
                    justify-content: start;
                    border: none;
                  }

                  .semi-select-arrow {
                    border: none;
                  }
                }
              }
            }
          }

          .form-item-group-divider {
            margin: 12px 0;
            border-bottom: 1px solid #e3e7ec;
          }
        }

        .button-group-wrapper {
          margin-bottom: 8px;
          margin-top: 24px;

          gap: 16px;
          display: flex;

          .semi-button {
            height: 36px;
            box-sizing: border-box;
            min-width: 142px;

            font-weight: 500;
            font-size: 14px;
            line-height: 22px;

            &.cancel-button {
              background-color: #f6f8fa;
            }

            &.save-button {
              background-color: #2e363a;
            }

            &.create-button {
              background-color: #3089d2;
              color: white;
            }
          }
        }

        .btn-cancel-test {
          padding: 0;
          height: initial;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #D1D7DD;
          border-radius: 4px;
        }
      }

      .right-wrapper {
        width: 50%;
        padding: 24px;

        display: flex;
        flex-direction: column;

        .title-wrapper {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 12px;

          .icon {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            border: 1px solid #e3e7ec;
            box-sizing: border-box;
            overflow: hidden;
          }

          .title {
            color: black;
            font-weight: 400;
            font-size: 32px;
            line-height: 44.8px;
          }
        }

        .content-wrapper {
          margin-top: 24px;
          padding: 0;
          font-weight: 400;
          font-size: 14px;
          color: var(--v2-color-grey900);
          line-height: 20px;
          flex: 1;
        }
      }
    }
  }

  .form-input-wrapper {
    .semi-select-selection-placeholder {
      color: var(--v2-color-grey500);
    }
  }
}

.integration-configuration-modal-container {
  .configuration-card {
    height: 80dvh;
  }

  .left-wrapper {
    overflow: auto;
  }

  .right-wrapper {
    overflow: auto;
  }
}
