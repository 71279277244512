@import "src/styles/minix";

.create-table-card {
  margin-top: 16px;

  .semi-card-body {
    padding: 24px;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: rgb(25, 30, 33);
    }

    .code-wrapper {
      background-color: #EDF1F4;
      border-radius: 4px;

      .code {
        margin-top: 24px;
        width: 100%;
        span {
          font-family: "Roboto Flex", sans-serif !important;
          font-weight: 400 !important;
        }


        > span {
          background-color: transparent !important;
        }

        position: relative;

        .copy-button {
          position: absolute;
          top: 16px;
          right: 16px;
          background-color: #FFF;

          &:active {
            background-color: #d1d7dd;
          }
        }
      }
    }

    .test-table-connection {
      position: relative;
      border-top: 1px solid #E3E7EC;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 16px;
      display: flex;
      flex-direction: row;

      .comfort-progress {
        position: absolute;
        top: -3px;
        left: 0;
        width: 100%;
      }

      .button-stauts-row {
        display: flex;
        align-items: center;
        gap: 16px;

        .status-info {
          .success-icon {
            width: 16px;
            height: 16px;
          }

          .error-status {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #C6362C;

            .error-icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
