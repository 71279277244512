@import "src/styles/minix";

.log-explorer-container {
  height: 100%;

  --search-field-height: 36px;

  .semi-form-vertical .semi-form-field {
    @include font(500);
    padding: 0;
  }

  .search-wrapper {
    .button-group {
      padding-left: 4px !important;

      .btn-refresh {
        width: 36px;
        height: 36px;
        border-radius: var(--semi-border-radius-medium);
        box-sizing: border-box;
        border: 1px solid var(--v2-color-line);
        background-color: var(--v2-color-grey50);

        .icon {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: var(--semi-color-fill-1);
        }
      }
    }
  }


  .content {
    position: relative;
    z-index: 1;
    padding: 36px 32px;

    .result-info {
      font-size: var(--semi-font-size-regular);
    }


    .request-log-chart {
      margin-top: 16px;
      border: 1px solid var(--v2-color-line);
      border-radius: var(--semi-border-radius-medium);
    }


    .logs-list {
      margin-top: 20px;

      .semi-table-container {
        background-color: var(--v2-background-color-light);
      }

      .pagination {
        margin-top: 16px;
      }

      .table-empty-default {
        min-height: 380px;
      }

      .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
        padding: 0 16px;
        height: 48px;
      }
    }

    .semi-button.semi-button-with-icon.btn-expand {
      img {
        width: 11px;
        height: 11px;
      }
    }

  }



}
