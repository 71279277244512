@import "src/styles/minix";

.generate-api-side-sheet {

  .semi-sidesheet-body {
    overflow-y: auto;
    padding-bottom: 19px;
  }

  .code-section {
    .get-key {
      background-color: var(--v2-color-blue50);
      font-size: 14px;
      line-height: 16px;
      padding: 12px;
      margin-bottom: 32px;
      color: var(--v2-color-grey700);
    }

    > .title {
      font-size: var(--semi-font-size-small);
    }

    .language-list {
      display: flex;
      flex-direction: row;
      margin: 11px 0;
      align-items: center;

      .semi-button.language-item {
        padding: 0;

        border-radius: var(--semi-border-radius-small);
        border: 1px solid #F7F7F7;
        //font-size: var(--semi-font-size-extra-small);
        margin-right: 8px;
        background-color: #FFF;

        width: 74px;
        height: 92px;
        box-sizing: border-box;

        .semi-button-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        &.active {
          background-color: #F7F7F7;
          border: 1px solid #D2D2D2;

          .name {
            color: var(--active-color);
          }
        }

        .name {
          @include font(500);
          margin-top: 8px;
          font-size: var(--semi-font-size-small);
          color: var(--description-text-color);
        }

        .icon {
          width: 23px;
          height: 23px;
        }
      }

      .btn-more {
        padding: 22px 11px;

        .icon {
          width: 16px;
        }

        &:hover {
          background-color: transparent;

          svg circle {
            fill: var(--semi-color-link)
          }
        }
      }
    }

    .code-area {
      border-radius: var(--semi-border-radius-small);
      overflow: hidden;
      margin-bottom: 19px;

      .code {
        background-color: var(--editor-code-background);

        > span {
          &::-webkit-scrollbar {
            display: none;
          }
        }

        code {
          min-height: 200px;
          max-height: 35vh;
          background: transparent;
          padding-bottom: 50px !important;

          &:first-child {
            background-color: var(--editor-active-color);
            user-select: none;
          }
        }
      }

      .operate-wrapper {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        height: 57px;
        background: linear-gradient(221deg, #525E70 0%, #3C4757 100%);

        .semi-button {
          flex: 1;
          height: 100%;
          border-radius: 0;
          color: var(--container-background-color);
          position: relative;
          outline: none;

          .icon {
            width: 13px;
            margin-right: 8px;
          }

          &.btn-copy {

            .uncopy {
              svg path {
                fill: #A8B3B7;
              }
            }
          }

          &:first-child {
            &:after {
              position: absolute;
              right: 0;
              content: '';
              display: block;
              height: 24px;
              width: 1px;
              opacity: 0.2;
              background-color: #DFDFDF;
            }
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .response-wrapper {
      margin-top: 11px;
      background-color: var(--semi-color-fill-1);
      border-radius: var(--semi-border-radius-small);
      //padding: 19px;

      &.hidden {
        display: none;
      }

      .response-header {
        margin: 0 19px;
        padding-top: 19px;
        padding-bottom: 11px;
        border-bottom: 1px solid var(--second-description-color);
        display: flex;
        flex-direction: row;
        align-items: center;

        .status-code {
          @include font(500);
          margin-left: 11px;
          padding-left: 11px;
          border-left: 1px solid var(--second-description-color);
          font-size: var(--semi-font-size-extra-small);
          line-height: 13px;

          .status {
            color: #4FCE7A;
            margin-right: 5px;
          }
        }
      }

      .response-body {
        display: flex;
        overflow-x: auto;
        margin-top: 19px;
        padding: 0 19px 19px;
        font-size: var(--semi-font-size-small);

        > span {
          overflow: initial !important;
          background: transparent !important;

          code {
            padding: 0 !important;
          }
        }
      }
    }


  }
}


.language-dropdown {
  box-shadow: 0 8px 31px 1px rgba(0, 0, 0, 0.05);

  .semi-dropdown-item {
    padding: 0;

    .language-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 120px;
      height: 37px;

      .name {
        @include font(500);
        color: var(--primary-text-color);
        //font-size: var(--semi-font-size-extra-small);
      }

      .icon {
        width: 23px;
        height: 23px;
        margin-right: 13px;
      }
    }

  }
}
