.metadata-previewer-wrapper {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .preview-data-button {
    margin-top: 73px;

    font-size: 12px;
    font-weight: 500;
    line-height: 22px;

    background-color: #fafbfc;
    border: 1px solid #e3e7ec;

    &:hover {
      background-color: #f6f8fa;
      border: 1px solid #d1d7dd;
    }

    &:active {
      background-color: #edf1f4;
      border: 1px solid #a1aab0;
    }

    &.disabled {
      background-color: white;
      border: 1px solid #edf1f4;
      color: #d1d7dd;
      cursor: not-allowed;
    }
  }

  .preview-progress {
    margin-top: 24px;
    width: 400px;
    height: 4px;
    border-radius: 0;

    &::-webkit-progress-bar {
      background-color: #e3e7ec;
    }

    &::-webkit-progress-value {
      background-color: #3089d2;
    }
  }

  .preview-progress-rate {
    margin-top: 8px;
    display: flex;
    gap: 9px;
    align-items: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    .percentage {
      background-color: #e3e7ec;
      padding: 5px 16px 3px 17px;
      border-radius: 4px;

      color: #444d56;
    }

    .timeused {
      color: #76808a;
    }
  }

  .query-result-table-wrapper {
    width: 100%;

    .result-wrapper {
      width: 100%;
      overflow: auto;
      border: 1px solid #E3E7EC;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}
