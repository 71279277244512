.integration-detail-header-main {
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e3e7ec;
  background-color: #FFF;

  .left {
    display: flex;
    gap: 8px;

    .icon {
      box-sizing: border-box;
      border: 1px solid #E3E7EC;
      width: 28px;
      height: 28px;
      overflow: hidden;
      border-radius: 4px;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 4px;

    .icon-wrapper {
      .icon {
        width: 16px;
        height: 16px;
        color: #a1aab0;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

      color: #a1aab0;
    }
  }
}
