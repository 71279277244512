.terminal-container {
  position: relative;
  overflow: hidden;
  background-color: var(--v2-background-color-light);
  width: 100%;
  display: flex;
  flex-direction: column;

  .panel-wrapper {
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  // fixed mask width bug
  > .semi-portal {
    width: initial !important;
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .sql-editor-pane {
      width: 100%;
    }
  }

  .query-result-pane {
    height: 100%;
  }
}

.dw-hover-modal {
  background: url('../../assets/icon/dataCloud/hover-modal.png');
}



.navigationBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  padding: 0 51px;
  color: #FFF;
  font-size: 12px;
  line-height: 15px;
  box-sizing: border-box;
  background-color: #00110E;

  a {
    color: #2188e9;

    &:hover {
      color: #005aaf;
    }
  }

  .signUp {
    :global span {
      text-decoration: underline;
      margin-left: 16px;
    }
  }

  .languageButton {
    background-color: transparent;
    border: 0;
    color: #FFF;
    cursor: pointer;
    margin-left: 20px;

    .languageButtonIcon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      display: flex;
      align-items: center;
    }
  }
}