.sync-job-detail-container {
  background-color: #f6f8fa;

  .header-main {
    background-color: white;
    border-bottom: 1px solid #e3e7ec;

    padding: 16px 32px;

    display: flex;
    justify-content: space-between;

    .title-indicator {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 11px;
      width: 100%;

      .job-details-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-wrapper {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 16px;

          .title {
            color: #191e21;

            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
          }

          .description {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;

            .icon {
              width: 14px;
              height: 14px;
            }
          }
        }

        .right-wrapper {
          .enable-switch {
            --chainbase-switch-scale: 1.25;

            height: fit-content;

            display: flex;
            align-items: center;
            gap: 9px;

            .switch-container {
              display: flex;
            }
          }
        }
      }

      .job-detail-data-to-integration {
        --icon-scale: 0.75;
        --font-size: 14px;
        --font-weight: 400;
        --line-height: 19.6px;
      }

      .sync-progress-wrapper {
        display: flex;
        align-items: end;
        gap: 8px;
        margin-bottom: 15px;

        .left-wrapper {
          flex: 1;
          .sync-progress-info-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-wrapper {
              display: flex;
              align-items: center;
              gap: 16px;

              .sync-status-wrapper {
                display: flex;
                align-items: center;
                gap: 4px;

                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;

                .icon {
                  width: 16px;
                  height: 16px;
                }
              }

              .percentage {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
                color: #76808a;
              }

              .warning-wrapper {
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 4px;

                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #8a4b00;

                .icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }

            .right-wrapper {
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: 0px;
              text-align: left;
              color: #76808a;
            }
          }

          .progress-bar {
            width: 100%;
            height: 8px;
            border-radius: 0px;

            &::-webkit-progress-bar {
              background-color: #e3e7ec;
              border-radius: 2px;
            }

            &::-webkit-progress-value {
              background-color: #3089d2;
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }

            &.paused::-webkit-progress-value {
              background-color: #76808a;
            }
          }
        }
      }
    }
  }

  .tabs-container {
    margin: 24px 32px;
    margin-bottom: 96px;
    
    .semi-tabs-content.semi-tabs-content-top {
      padding-block: 16px;
    }

    .semi-tabs-tab {
      padding: 0 !important;

      fill: var(--description-text-color);
      stroke: var(--description-text-color);

      &.semi-tabs-tab-active {
        fill: var(--primary-text-color);
        stroke: var(--primary-text-color);
      }

      .tab {
        padding: 2px 5.83px 5px 7.5px;

        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          width: 16px;
          height: 16px;
        }

        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 19.6px;
        }
      }

      &.semi-tabs-tab-active::after {
        background-color: #3089d2 !important;
        height: 2px;
      }
    }
  }
}
