

.learn-more {
  display: inline-flex;
  align-items: center;
  text-decoration-line: underline;
  color: var(--v2-color-blue400);
  font-size: var(--semi-font-size-small);
  line-height: var(--semi-font-size-regular);

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
