
button.normal {
  padding: 0;
  font-family: initial;
}

.btn-json-copy {
  @include font(500);
  padding: 0 15px;
  margin-right: -15px;
  color: var(--description-text-color);
}

.SplitPane {

  > .Pane.horizontal {
    height: 100%;
    overflow: hidden;
  }

  > .Pane.vertical {

    > .Pane {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  .Resizer {
    background: var(--primary-text-color);
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.vertical {
    width: 4px;
    margin: 0;
    background-color: var(--v2-color-line);
    border-right: 1px solid var(--v2-color-line);
    cursor: col-resize;
    height: 100%;
    opacity: 1;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
}


.split-panel-wrapper {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;


  .vertical.Pane2 {
    width: 100%;
    overflow: hidden;
  }
}


.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}