@import "../../styles/minix";

table.result-table {
  border-collapse: collapse;
  position: relative;
  font-size: var(--semi-font-size-small);
  line-height: var(--semi-font-size-regular);

  th, td {
    border-right: 1px solid var(--v2-background-color-light);

    &:last-child {
      border-right: 0;
    }
  }

  thead {
    position: sticky;
    top: 0;
    background-color: var(--v2-color-grey200);
    color: var(--v2-color-grey700);

    th {
      @include font(500);
      padding: 8px;
      text-align: center;
    }
  }

  tbody {
    tr {
      height: var(--height-control-extra-large);
      &:hover {
        background-color: var(--v2-background-color-light);
      }

      &:nth-child(odd) {
        td {
          border-right: 1px solid var(--v2-color-grey100);
        }
      }
      &:nth-child(even) {
        background-color: var(--v2-color-grey100);
      }
    }
  }

  td {
    padding: 12px 8px;
    white-space: nowrap;
    color: var(--v2-color-grey700);
    min-width: 60px;
    height: 38px;
    box-sizing: border-box;
  }
}
