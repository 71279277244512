.header {
  height: 73px;
  padding: 0 32px;
  background-color: var(--v2-background-color-light);
  border-bottom: 1px solid var(--v2-color-line);
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 99;

  .semi-breadcrumb-item-wrap {
    a {
      padding-inline: 4px;
      border-radius: 4px;

      &:hover {
        background-color: #E0F2FF;
      }
    }
  }

  .semi-breadcrumb-item-wrap:last-of-type {
    .semi-breadcrumb-item-title {
      cursor: default;
    }
  }

  &.sub-page {
    height: 43px;
    flex-shrink: 0;
  }

  .sub-page-breadcrumb {
    .semi-breadcrumb-item-link {
      color: var(--v2-color-blue700);
    }

    .semi-breadcrumb-item-active {
      color: var(--v2-color-grey600);
    }
  }

  .description {
    margin-top: 4px;
    font-size: 12px;
    color: var(--v2-color-grey500);
  }
}

.header-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 22px;
    height: 22px;
    margin-right: 9px;

    svg path {
      fill: var(--v2-color-grey700);
    }
  }

  h3 {
    font-size: 24px;
  }
}
