.comfort-progress {
  height: 4px;
  background-color: #e3e7ec;

  .progress-value {
    height: 4px;
    background-color: #3089d2;
    transition: width 0.3s ease-in-out;
  }
}
