
.usage-container {
  height: initial !important;
  padding-right: 48px;

  .block {
    padding: 20px 24px 24px;
    height: 307px;
    border: 1px solid var(--v2-color-grey200);
    box-sizing: border-box;

    .title {
      font-size: var(--semi-font-size-small);
      line-height: 24px;
    }
  }

  > .title {
    margin-top: 60px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    color: var(--v2-color-grey700);
  }


  .setting-panel {
    margin-top: 13px;
    margin-bottom: 25px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;

      .icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      .name {
        font-size: 20px;
        color: var(--v2-color-grey700);
        margin-left: 9px;
      }
    }
  }

  .header-title {
    margin-bottom: 16px;

    h3 {
      font-size: 28px;
    }
  }

  .plan-expires-tip {
    background-color: #FBF6EA;
    border-radius: 4px;
    padding: 10px 12px;
    display: inline-flex;
    line-height: 16px;
    align-self: baseline;
    margin-top: 16px;
    margin-bottom: 11px;
    font-size: var(--semi-font-size-small);
  }


  .plan-list {
    display: flex;
    flex-direction: row;
    margin-top: 44px;

    .plat-item {
      margin-right: 16px;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .current-outline {
        width: 364px;
        height: calc(100% + 2px);
        margin: auto;
        position: absolute;
        z-index: 0;
        border: 3px solid var(--v2-color-blue500);
        display: none;

        &.active {
          display: block;
        }
      }

      &.enterprise .card-content .planItemline {
        background: #FFFFFF;
        opacity: 0.2;
      }

      .card-content {
        box-sizing: border-box;
        width: 358px;
        height: 628px;
        text-align: center;
        padding: 24px;
        background-color: var(--v2-color-grey50);
        position: relative;
        z-index: 1;
        border: 1px solid #E3E6E8;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &.team .card-content {
        background-color: #E1F1FF;
        border: 1px solid #B7D5E6;

        .desc {
          color: #1C2A4E;
        }
      }

      &.enterprise .card-content {
        background: #010101;
        color: #FFF;
        border: 0px;

        .desc {
          color: #FFF;
        }

        .btn-more {
          span {
            color: #FFF;
          }
        }

        .btn-plan {
          color: #FFF;

          &.custom {
            font-size: 18px;
            background: #FFFFFF;
            color: var(--v2-color-grey700);
          }
        }
      }

      .planItemline {
        width: 292px;
        background-color: #E8EDED;
        height: 1px;
        margin-top: 36px;
      }
      .planItemPopularTag {
        width: 97px;
        background-color: #DDD1FF;
        border-radius: 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin: -16px 0;
      }

      .tag {
        display: flex;
        align-items: center;
        border-radius: 14px;
        padding: 5px 10px;
        color: var(--v2-color-blue500);
        font-size: 14px;
        line-height: 16px;
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
      }

      .btn-unsubscribe {
        text-decoration-line: underline;
        color: var(--v2-color-grey700);
        font-size: 12px;
        line-height: 14px;
        position: absolute;
        margin: 8px auto 0;
        left: 0;
        right: 0;
        display: inline-block;
      }

      .name {
        margin-top: 12px;
        margin-bottom: 10px;
        font-size: 28px;
      }

      .desc {
        font-size: 14px;
        color: var(--primary-text-color);
        opacity: 0.5;
      }

      .price {
        font-size: 56px;
        line-height: 68px;
        margin-top: 49px;
        margin-bottom: 24px;
        font-weight: 600;
        display: inline-flex;

        .doller {
          color: #1E1E1E;
          font-size: 19px;
          line-height: 22px;
          margin-top: 5px;
          margin-right: 5px;
        }

        .month {
          color: #1E1E1E;
          font-size: 19px;
          line-height: 22px;
          align-self: flex-end;
          margin-bottom: 5px;
        }
      }

      .semi-skeleton-button {
        width: 100%;
        height: 54px;
      }

      .btn-plan {
        width: 100%;
        height: 54px;
        border: 1px solid #E3E6E8;
        border-radius: 4px;
        user-select: none;
        transition: all 0.25s;

        &:hover {
          transform: scale(1 - 0.02);
          opacity: 0.9;
        }

        &[disabled] {
          color: var(--v2-color-grey300);
          cursor: not-allowed;
        }

        &.upgrade {
          background: linear-gradient(180deg, #4FA0FF 0%, #3073E3 100%);
          border: 0;
          color: #FFF;
          font-size: var(--normal-font-size);
        }
      }

      .feature-wrapper {
        padding: 40px 12px 13px;
        text-align: left;
        width: 294px;

        .tip {
          font-size: 14px;
          line-height: 17px;
        }

        .feature-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin: 12px 0;

          p {
            font-size: 14px;
            line-height: 16px;
          }

          .icon {
            width: 13px;
            height: 13px;
            margin-right: 6px;
            margin-top: 2px;
          }
        }

        .btn-more {
          margin-top: 13px;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: left;
          cursor: pointer;

          .icon {
            width: 10px;
            height: 10px;
          }

          span {
            margin-left: 7px;
            font-size: 12px;
            color: var(--v2-color-grey700);
            text-decoration: underline;
          }
        }
      }
    }
  }

  .payment-method {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    background: #FAFAFA;
    border: 1px solid #E3E6E8;
    border-radius: 4px;
    font-size: var(--semi-font-size-small);
    color: var(--v2-color-grey700);

    .icon {
      width: 24px;
      height: 24px;
      border-radius: var(--semi-border-radius-medium);
    }

    .name {
      margin-left: 12px;
    }

    .payment-card {
      margin-left: 13px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;

      .card-number {
        margin-left: 3px;
      }

      .dot-group {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;

        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: var(--v2-color-grey400);
          margin: 0 3px;
          display: inline-block;
        }
      }
    }

    .delimiter {
      margin-left: 24px;
      height: 22px;
      border-left: 1px solid #E3E6E8;
    }

    .expires {
      margin-left: 24px;
    }

    .btn-change {
      margin-left: auto;
      padding: 10px 21px;
      width: 92px;
      height: 36px;
      font-size: 14px;
    }


    .empty-tip {
      padding-left: 8px;
      color: var(--v2-color-grey400);
    }
  }

  .billing-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 24px 20px 16px;
    border: 1px solid #E3E6E8;
    border-radius: 4px;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;

    .label {
      color: var(--v2-color-grey400)
    }
  }

  .invoice-table {

    .table-empty {
      height: 420px;

      .semi-table-placeholder {
        height: 400px;
      }

      .semi-table-empty {
        height: 100%;
        .icon {
          width: 182px;
        }
        .table-empty-default {
          height: 100%;
        }
      }
    }

    .btn-view {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--v2-color-grey700);

      .icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }

      &:hover {
        color: var(--semi-color-link);

        .icon {
          svg line,
          svg path {
            stroke: var(--semi-color-link);
          }
        }
      }
    }

    .payment-method-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 18px;
        height: 18px;
      }

      .card {
        margin-left: 10px;
        color: var(--v2-color-grey700);
      }
    }

    .pagination {
      margin-top: 16px;
    }
  }
}


.card-modal {


  .semi-modal {
    .error-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      padding: 4px 9px;
      min-height: 28px;
      box-sizing: border-box;
      color: var(--semi-color-danger);

      .icon-error {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      .btn-close {
        margin-left: auto;
        svg path {
          stroke: var(--semi-color-danger);
        }

        .icon-close {
          width: 10px;
          height: 10px;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    .card-form {
      margin-top: 16px;
    }

    .semi-modal-footer > div .semi-button {
      width: 100%;
    }

    .error-message {
      width: 100%;
      background-color: var(--semi-color-light-danger);
    }
  }

}

.payment-modal {

  .semi-modal-body {
    padding-top: 16px;
  }

  .payment-status {
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 180px;
      padding-bottom: 28px;
    }

    .loading {
      width: 54px;
      height: 54px;
      border-width: 4px;
    }

    &.payment-failed {
      .icon {
        width: 54px;
        height: 54px;
      }
    }

    &.payment-success {

      .icon {
        height: 123px;
      }

      .desc {
        margin-top: 10px;
      }

    }

    .desc {
      margin-top: 30px;
      font-size: var(--normal-font-size);
    }

    .tip {
      margin-top: 10px;
      font-size: var(--semi-font-size-extra-small);
      line-height: var(--semi-font-size-extra-small);
      color: var(--v2-color-grey400);
    }

    .btn {
      width: 100%;
    }
  }
}

.upgrade-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.downgrade {
    .icon {
      height: 119px;
    }
  }

  .icon {
    margin-top: 8px;
    height: 142px;
  }

  .desc {
    margin-top: 13px;
    color: var(--semi-color-text-0);
    font-size: var(--normal-font-size);
  }

  .tip {
    font-size: var(--semi-font-size-extra-small);
    margin-top: 10px;
    color: var(--v2-color-grey400);
    text-align: center;
  }

  .semi-modal-footer {
    margin-top: 36px;
    width: 100%;

    .semi-button[aria-label="confirm"] {

      &.Upgrade {
        background: linear-gradient(221.38deg, #2F7AF7 -26.8%, #2358AF 102.98%);

        &:hover {
          background: linear-gradient(221.38deg, #2F7AF7 -26.8%, #2358AF 102.98%) !important;
        }
      }

      &.Downgrade {
        background: var(--v2-color-grey600);

        &:hover {
          background: var(--v2-color-grey500) !important;
        }
      }
    }
  }
}
