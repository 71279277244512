@import "src/styles/minix";

.delete-popover-body {
  width: 188px;
  padding: 19px 24px;

  .title {
    @include font(500);
    font-size: var(--semi-font-size-small);
    font-weight: 400;
    text-align: center;
  }

  .button-group {
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      @include font(400);
      font-size: var(--semi-font-size-extra-small);
      padding: 8px 14px;
      border: none;
      margin: 0 10px;
      outline: none;

      &.btn-cancel {
        background-color: var(--container-background-color);
        color: var(--primary-text-color);
        transition: background-color 0.35s ease-in-out;

        &:hover {
          background-color: var(--semi-link-button-fill);
        }
      }

      &.btn-delete {
        color: var(--semi-color-danger);
        background-color: var(--semi-color-light-danger);
        min-width: 62px;

        .loading {
          width: 18px;
          height: 18px;
        }
      }

      &:hover {
        border: none;
      }
    }
  }
}

