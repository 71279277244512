.code-editor {
  // position: relative;

  .btn-format {
    position: absolute;
    bottom: 26px;
    right: 38px;

    background-color: var(--v2-color-grey600);
    font-size: var(--semi-font-size-extra-small);
    color: var(--v2-color-grey400);
    padding: 8px 12px;

    .icon {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: #3d454e !important;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  .monaco-editor {
    .monaco-editor-background {
      background-color: var(--v2-color-grey700);
    }

    .margin {
      background-color: #2E363A;
    }
  }

}

input.parameter-value {

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type=number]::-webkit-outer-spin-button,
  &[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: #A1AAB0;
    font-size: 10px;
    line-height: 18px;
    position: relative;
    bottom: 1px;
    left: 2px;
  }

}
