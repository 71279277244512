
.payload-preview {
  max-height: 100%;
  overflow-y: auto;

   .payload-item {
    font-size: var(--semi-font-size-small);
    padding: 6px 0;
    width: 100%;
    word-break: break-all;

    .key {
      width: 70px;
      display: inline-block;
      text-transform: capitalize;
      color: var(--primary-text-color);
    }

    .value {
      color: #5A5A5A;
    }
  }
}
