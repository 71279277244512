.login {
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-form {
    .input-control {
      .semi-form-field {
        padding-top: 0;
      }
    }

    .btn-forget {
      font-size: var(--semi-font-size-extra-small);
      color: var(--description-text-color);
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #303030;
      }
    }
  }

  .btn-signup {
    margin-top: 24px;
    width: var(--input-width);
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--semi-font-size-regular);
    color: var(--primary-color);

    > a {
      margin-left: 10px;
    }
  }
}

.wallet-login {
  width: var(--input-width);
  height: var(--input-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 9px;
  border: 1px #f0f0f0 solid;
  padding-left: 21px;
  padding-right: 18px;
  justify-content: space-between;
  box-sizing: border-box;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--container-background-color);
  }

  .wallet-icon-list {
    display: flex;
    flex-direction: row;

    .wallet-item {
      display: flex;

      &:nth-child(2) {
        img {
          opacity: 0.72;
        }
      }

      &:nth-child(3) {
        img {
          opacity: 0.2;
        }
      }

      .icon {
        height: 18px;
        margin-right: 15px;
      }
    }
  }

  .placeholder {
    font-size: 12px;
    color: var(--primary-text-color);
  }

  .placeholder-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-left: 12px;
      height: 12px;
    }
  }
}

.or-line {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 36px;

  span {
    z-index: 1;
    padding: 0 9px;
    background-color: #ffffff;
    color: var(--primary-text-color);
  }

  &:before {
    position: absolute;
    display: block;
    content: ' ';
    width: 100%;
    border: 1px dashed #f2f2f2;
  }
}

.card-content-container {
  --wallet-modal-width: 558px;
  --wallet-item-width: 451px;
  --wallet-item-height: 80px;

  z-index: 5;
  position: relative;
  overflow: hidden;

  .card.card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--wallet-modal-width);
    background-color: #fff;
    padding-top: 52px;
    padding-bottom: 43px;

    .title {
      width: var(--wallet-item-width);
      color: var(--primary-text-color);
      font-size: 21px;
      margin-bottom: 46px;
    }

    .wallet-icon-list {
      display: flex;
      flex-direction: column;

      .wallet-item {
        width: var(--wallet-item-width);
        height: var(--wallet-item-height);
        padding-right: 27px;

        display: flex;
        align-items: center;

        border-radius: 9px;
        margin: 9px 0;
        background-color: #f9f9f9;
        transition: background-color 0.35s;

        &:hover {
          background-color: #f3f3f3;
        }

        .image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 108px;
          .icon {
            height: 36px;
          }
        }

        .name {
          font-size: 16px;
          flex: 1;
          text-align: left;
        }

        .popular {
          font-size: 14px;
          color: var(--description-text-color);
        }

        .icon {
          margin-left: 18px;
          height: 12px;
        }
      }
    }

    .btn-guide {
      margin-top: 44px;
      font-size: 14px;

      a {
        background-color: transparent;
        color: var(--description-text-color);
        transition: opacity 0.35s;

        &:hover {
          opacity: 0.72;
        }
      }
    }
  }
}
