.sync-jobs-error-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;

  .disable-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 100;
  }

  .toast-wrapper {
    position: absolute;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 150;
    top: 5%;
    left: calc(50%);
    transform: translateX(-50%);
    min-width: 360px;
    padding-inline: 16px;

    border: 1px solid #C6362C;
    background-color: #FEF4F3;
    border-radius: 4px;

    .left-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    .right-wrapper {}
  }
}