.empty-content-wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    //styleName: H4(20)/H4(20)-Medium;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #191e21;
  }

  .desc {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #a1aab0;
    width: 338px;
  }

  .btn {
    margin-top: 24px;
    padding: 10px 16px;
  }
}
