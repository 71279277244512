@import 'src/styles/minix';

.generate-api-side-sheet {
  .semi-sidesheet-body {
    overflow-y: auto;
    padding-bottom: 19px;
  }

  .code-section {
    .get-key {
      background-color: var(--v2-color-blue50);
      font-size: 14px;
      line-height: 16px;
      padding: 12px;
      margin-bottom: 32px;
      color: var(--v2-color-grey700);
    }

    .response-wrapper {
      margin-top: 11px;
      background-color: var(--semi-color-fill-1);
      border-radius: var(--semi-border-radius-small);
      //padding: 19px;

      &.hidden {
        display: none;
      }

      .response-header {
        margin: 0 19px;
        padding-top: 19px;
        padding-bottom: 11px;
        border-bottom: 1px solid var(--second-description-color);
        display: flex;
        flex-direction: row;
        align-items: center;

        .status-code {
          @include font(500);
          margin-left: 11px;
          padding-left: 11px;
          border-left: 1px solid var(--second-description-color);
          font-size: var(--semi-font-size-extra-small);
          line-height: 13px;

          .status {
            color: #4fce7a;
            margin-right: 5px;
          }
        }
      }

      .response-body {
        display: flex;
        overflow-x: auto;
        margin-top: 19px;
        padding: 0 19px 19px;
        font-size: var(--semi-font-size-small);

        > span {
          overflow: initial !important;
          background: transparent !important;

          code {
            padding: 0 !important;
          }
        }
      }
    }

    .progress-wrapper {
      background-color: #f6f8fa;
      border: 1px solid #e3e7ec;
      border-radius: 8px;
      padding: 47px 36px 64px 36px;

      .percentage {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #444d56;

        text-align: center;
      }

      .progress {
        margin-top: 16px;
        width: 100%;
        background-color: #e3e7ec;
        height: 4px;

        &::-webkit-progress-value {
          background-color: #3089d2;
          transition: width 0.3s ease-in-out;
        }

        &::-webkit-progress-bar {
          background-color: #e3e7ec;
        }
      }
    }
  }
}

.language-dropdown {
  box-shadow: 0 8px 31px 1px rgba(0, 0, 0, 0.05);

  .semi-dropdown-item {
    padding: 0;

    .language-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 120px;
      height: 37px;

      .name {
        @include font(500);
        color: var(--primary-text-color);
        //font-size: var(--semi-font-size-extra-small);
      }

      .icon {
        width: 23px;
        height: 23px;
        margin-right: 13px;
      }
    }
  }
}
