.block.table-navigation {
  position: relative;
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 28px 7px 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: none;

  .table-type-tabs {
    margin-top: 18px;
    flex-direction: column;
    height: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;

    .semi-tabs-pane {
      overflow: initial;
    }

    .semi-tabs-content {
      flex: 1;
      overflow-y: auto;


      /* width */
      &::-webkit-scrollbar {
        display: none;
        width: 0;
      }
    }

    .semi-tabs-bar {
      margin: 0 17px;

      display: flex;
      flex-direction: row;

      .semi-tabs-tab {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }

}
