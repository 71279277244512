
.archived-queries-container {

  .archived-queries-list {
    margin: 32px;

    .semi-table-wrapper {
      background-color: var(--v2-background-color-light);
    }

    .blockchain {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 21px;
        height: 21px;
        margin-right: 8px;
      }
    }

    .pagination {
      margin-top: 16px;
    }
  }

  .archived-queries-block {
    flex: 1;
    display: flex;
    flex-direction: column;

    .chainbase-table {
      flex: initial;
    }

    .btn-more {
      position: relative;
      width: 38px;
      height: 38px;

      .icon {
        width: 18px;
        transform: rotate(90deg);
      }
    }
  }
}
