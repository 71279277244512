.sync-overview-header {
  background-color: white;
  height: 72px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 32px;

  border-bottom: 1px solid #E3E7EC;

  & h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  & p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
    color: #a1aab0;

    margin-top: 4px;
  }

  & .tail {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    & .doc-link {
      display: flex;
      gap: 4px;
      color: gray;

      font-weight: 400;
      font-size: 14px;
    }

    & .new-job-button {
      height: 36px;

      & .semi-button-content {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;

        padding: 16px 10px;
      }

      & .dynamic-svg-icon {
        width: 16px;
        height: 16px;
      }

      font-weight: 500;
      font-size: 14px;
      line-height: 16.41px;
    }
  }
}
