.setup-source-container {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  .two-cards {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 465px;
    max-height: calc(100vh - 340px);

    display: flex;
    gap: 16px;

    .card {
      background-color: white;
      border: 1px solid #e3e7ec;
      border-radius: 8px;
    }

    .left-card {
      padding-top: 24px;
      width: 33%;
    }

    .right-card {
      width: 66%;
      padding: 22px 24px;
      border: 1px solid #e3e7ec;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .detail-title {
        font-weight: 600;
        font-size: 18px;

        .detail-title-blockchain {
          color: #3089d2;
        }
      }

      .detail-desc {
        margin-top: 8px;
        color: #a1aab0;
        font-weight: 400;
        font-size: 14px;
      }

      .right-card-tabs {
        margin-top: 12px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .semi-tabs-tab-active {
          &::after {
            background-color: #3089d2;
            height: 2px;
          }
        }

        .semi-tabs-content {
          padding-block: 10px;
          height: 100%;

          .semi-tabs-pane {
            height: 100%;

            .semi-tabs-pane-motion-overlay {
              height: 100%;
            }
          }
        }
      }

      .detail-schema-table {
        display: flex;
        flex-direction: column;
        height: 100%;

        .detail-schema-table-title {
          margin-bottom: 8px;
        }

        .sync-table-container {
          height: 100%;
        }
      }

      .sync-tag {
        background-color: #eff3f9;
        color: #2272b4;
      }
    }
  }

  .bottom-display {
    margin-top: 20px;
  }

  .table-tree .semi-tree-option-list .semi-tree-option {
    &::before {
      transition: opacity 100ms;
      background: linear-gradient(0deg, #e0f2ff, #e0f2ff),
        linear-gradient(0deg, #3089d2, #3089d2);
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      top: 0;
      left: 0;
      box-sizing: border-box;
      border: 1px solid #3089d2;
      border-radius: 4px;
      z-index: 0;
      pointer-events: none;
      opacity: 0;
    }

    &.semi-tree-option-selected {
      position: relative;

      > * {
        z-index: 1;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}
