body {
  --layout-header-height: 50px;
  --primary-text-color: #1e1e1e;
  --description-text-color: #a0a0a0;
  --second-description-color: #dfdfdf;
  --disabled-color: #d9d9d9;
  --active-color: #242424;

  --editor-active-color: #2E363A;
  --editor-code-background: #152030;

  --semi-color-link: #2f7af7;
  --semi-link-button-fill: #eeeeee;
  --success-color: #27bc37;

  --normal-font-size: 16px;
  --semi-font-size-small: 12px;
  --semi-font-size-regular: 14px;
  --semi-font-size-large: 16px;
  //--semi-font-size-extra-small: 12px;

  --container-background-color: var(--v2-color-grey50);
  --semi-color-primary: var(--v2-color-grey800);
  --semi-color-primary-hover: var(--v2-color-grey700);
  --semi-color-primary-active: var(--v2-color-grey900);

  --semi-color-info: #4f9ce3;
  --semi-color-success: #41af74;
  --semi-color-success-light-default: #edfbed;
  --semi-color-danger: #d25252;
  --semi-color-light-danger: #ffdedd;
  --semi-color-warning: #e3ba4f;
  --semi-color-assist: #8664b5;

  --semi-input-border-radius: 8px;

  --semi-border-radius-extra-small: 2px;
  --semi-border-radius-small: 4px;
  --semi-border-radius-medium: 4px;
  --semi-border-radius-large: 6px;

  --semi-color-fill-0: #f7f7f7;

  --semi-color-border: var(--v2-color-line);
  --border-color-tertiary: #edeff0;

  /* Text */
  --semi-color-text-0: var(--primary-text-color);
  --semi-color-text-1: var(--description-text-color);
  --semi-color-text-2: #d2d2d2;

  --semi-color-disabled-text: var(--description-text-color);
  --semi-color-disabled-bg: #f2f3f4;

  /* Input */
  --input-height: 53px;
  --input-height-small: 34px;
  --semi-color-fill-1: #f3f3f3;
  --semi-color-focus-border: #d2d2d2;
  --semi-color-danger-light-default: #fff0f0;
  --semi-color-danger-light-hover: #fff0f0;

  /* Modal */
  --semi-color-overlay-bg: rgba(0, 0, 0, 0.5);

  /* V2 color */
  --height-control-extra-small: 24px;
  --height-control-small: 28px;
  --height-control-default: 32px;
  --height-control-large: 36px;
  --height-control-extra-large: 40px;

  --v2-color-line: #e3e6e8;
  --v2-color-line-on-dark: #444d56;

  --v2-color-grey50: #FAFBFC;
  --v2-color-grey100: #F6F8FA;
  --v2-color-grey200: #EDF1F4;
  --v2-color-grey300: #E3E7EC;
  --v2-color-grey400: #a4aab0;
  --v2-color-grey500: #A1AAB0;
  --v2-color-grey600: #76808A;
  --v2-color-grey700: #191e21;
  --v2-color-grey800: #252b2e;
  --v2-color-grey900: #191e21;

  --v2-color-blue50: #edf4ff;
  --v2-color-blue100: #E0F2FF;
  --v2-color-blue200: #95bdff;
  --v2-color-blue300: #69a1ff;
  --v2-color-blue400: #3c7eeb;
  --v2-color-blue500: #2b6ede;
  --v2-color-blue600: #3089D2;
  --v2-color-blue700: #2272B4;
  --v2-color-blue-gradient: linear-gradient(221.38deg, #4383ef -26.8%, #3073e3 102.98%);
  --v2-background-color-light: #fff;

  --v2-color-green300: #BBF3BA;
  --v2-color-green700: #3F983D;

  --v2-color-red100: #FFEBEA;
  --v2-color-red600: #C6362C;

  --data-cloud-editor-parameter-list-scroll-bar-height: 6px;
}
