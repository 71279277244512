@import '../../../../styles/minix';

.log-detail-side-sheet {
  min-width: 678px;

  .semi-sidesheet-inner-wrap {
    box-shadow: -41px 0 51px 0 rgba(0, 0, 0, 0.04);
  }

  .semi-sidesheet-body {
    padding: 0;
    overflow: hidden;
  }

  .semi-sidesheet-header .semi-sidesheet-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .semi-typography {
      @include font(500);
      margin-left: 19px;
      font-size: var(--semi-font-size-regular);
    }
  }

  .btn-copy {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--semi-color-link);
    font-size: var(--semi-font-size-regular);
    @include font(500);
    padding-left: 10px;
    padding-right: 19px;
    height: var(--input-height);

    .icon {
      width: 13px;
      margin-right: 10px;

      svg path {
        fill: var(--semi-color-link);
      }
    }
  }

  .json-viewer {

    .semi-tabs {
      height: 100%;

      .semi-tabs-bar-line {
        padding: 0 28px;

        .semi-tabs-bar-extra {
          height: 38px;
          display: flex;
          align-items: center;
          flex-direction: row;
        }
      }

      .semi-tabs-content {
        height: calc(100% - 58px);
        padding: 18px 28px;
        box-sizing: border-box;

        .semi-tabs-pane,
        .semi-tabs-pane-motion-overlay {
          width: 100%;
          height: 100%;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .btn-copy {
      padding-right: 0;
    }

    .json-view {
      box-sizing: border-box;
      padding: 6px 0;
      outline: none;
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  .response-data-viewer {
    border-top: 1px solid var(--semi-color-border);

    .disabled-text {
      color: var(--v2-color-grey500);
    }
  }
}
