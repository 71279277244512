@import "src/styles/minix";

.version-switch {
  .btn-switch {
    height: 24px;
    padding: 0 8px;
    color: #A1AAB0;
    position: relative;
    @include font(500);

    &.hover {
      background-color: #444D56 !important;
      color: #FFFFFF;
      transition: all 200ms;

      .dynamic-svg-icon {
        transform: rotate(90deg);
      }
    }

    .dynamic-svg-icon {
      transition: transform 200ms;
      transform: rotate(270deg);
      width: 8px;
      height: 8px;
      display: flex;
      path {
        stroke: #A1AAB0;
      }
    }
  }
}

.semi-popover-wrapper[x-placement=bottom] {
  .semi-popover-icon-arrow {
    top: -6px
  }
}

.version-switch-popover {
  .semi-popover-content {
    box-shadow: 0 4px 10px 0 #081117B2;
    border-radius: 4px;
    overflow: hidden;
    background-color: #2E363A;
  }

  .btn-version {
    min-width: 132px;
    height: 28px;
    padding: 0 12px;
    box-sizing: border-box;
    color: #A1AAB0;

    &:hover {
      color: #FFFFFF;
    }

    .semi-button-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &:hover {
      background-color: transparent;
    }

    .new {
      background-color: #BDB4FF;
      display: inline-block;
      padding: 0 5px;
      line-height: 12px;
      font-size: 10px;
      border-radius: 20px;
      margin-left: 8px;
      color: #000000;
    }
  }

}