@keyframes fadeIn {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes toggleDialog {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.parameter-editor[open] {
  animation: toggleDialog 0.2s;
  animation-direction: normal;
}

.parameter-editor-close {
  animation: toggleDialog 0.1s;
  animation-direction: reverse;
}

.parameter-editor-warpper {
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.parameter-editor-warpper-open {
  visibility: visible;
  animation: fadeIn 0.1s;
  animation-fill-mode: both;
}

.parameter-editor-warpper-close {
  animation: fadeOut 0.3s;
  animation-fill-mode: forwards;
}

.hidden {
  visibility: hidden;
}