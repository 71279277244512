
.code-editor {
  position: relative;
  display: flex;
  flex: 1;


  .btn-format {
    position: absolute;
    bottom: 26px;
    right: 38px;


    background-color: var(--v2-color-grey600);
    font-size: var(--semi-font-size-extra-small);
    color: var(--v2-color-grey400);
    padding: 8px 12px;

    .icon {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: #3D454E !important;
      color: #FFF;

      svg path {
        fill: #FFF;
      }
    }
  }

  .monaco-editor {
    .monaco-editor-background {
      background-color: var(--v2-color-grey700);
    }

    .margin {
      //background-color: var(--v2-color-grey700);
      background-color: #333D43;
    }
  }
}

.code-editor-v1 {
  height: 100%;
}