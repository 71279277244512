

.parameter-wrapper {
  background-color: #2E363A;
  border-top: 1px solid #444D56;
}

.parameter-list .semi-overflow-list-scroll-wrapper {
  padding-bottom: 4px;
}

.parameter-list .semi-overflow-list-scroll-wrapper::-webkit-scrollbar {
  margin-top: 2px;
  height: var(--data-cloud-editor-parameter-list-scroll-bar-height);
  border-radius: 4px;
}

.parameter-list .semi-overflow-list-scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: #444d56;
}
