.sync-data-to-integration {
  // --icon-scale: 1;
  // --font-size: 16px;
  // --font-weight: 600;
  // --line-height: 22.4px;

  display: flex;
  align-items: center;
  gap: 16px;

  .label {
    color: #191e21;

    font-style: normal;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
  }

  .icon-label-warpper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .five-arrows {
    width: calc(25px * var(--icon-scale));
    height: calc(12px * var(--icon-scale));
  }

  .blockchains-wrapper {
    position: relative;
    margin-right: 8px;
  }

  .left {
    .icon-warpper {
      display: flex;
      align-items: center;
      .icon {
        width: calc(24px * var(--icon-scale));
        height: calc(24px * var(--icon-scale));
      }
    }
  }

  .right {
    .icon-warpper {
      --icon-size: calc(28px * var(--icon-scale));

      padding: 3px;
      width: var(--icon-size);
      height: var(--icon-size);

      border-radius: 4px;
      border: 1px solid #e3e7ec;
      background: #fff;

      .icon {
        width: var(--icon-size);
        height: var(--icon-size);
      }
    }
  }
}
