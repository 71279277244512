.job-history-container {
  .job-history-container-header {
    padding: 14px 16px;
    background-color: white;
    border: 1px solid #e3e7ec;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      color: #a1aab0;

      .selector {
        min-width: 100px;

        .semi-select-selection {
          justify-content: start;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .status {
        display: flex;
        align-items: center;
        gap: 6px;

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 19.6px;
        }
      }

      .next-sync {
        margin-left: 16px;

        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
        color: #76808a;
      }

      .sync-button {
        margin-left: 24px;
        padding: 3px 12px;

        .semi-button-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  &.history-empty {
    .job-history-container-header {
      border-radius: 8px;
    }
  }

  &.history-non-empty {
    .job-history-container-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .job-history-panel:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .collapse-container {
    padding: 0;
    background-color: white !important;

    .semi-collapse-item {
      border: 1px solid #e3e7ec;
      border-top: none;

      .semi-collapse-header {
        margin: 0;
        padding: 16px;

        align-items: start;

        .panel-header {
          width: 100%;

          display: flex;
          justify-content: space-between;

          .left {
            display: flex;
            gap: 8px;
            align-items: start;

            .icon-wrapper {
              margin-top: 1px;
            }

            .text-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .status {
                font-weight: 600;
                font-size: 14px;
                line-height: 19.6px;
              }

              .detail {
                font-weight: 400;
                font-size: 12px;
                line-height: 16.8px;

                display: flex;
                gap: 8px;

                & span:not(:last-of-type)::after {
                  content: '|';
                  margin-left: 8px;
                  color: #e3e7ec;
                }
              }
            }
          }

          .right {
            margin-right: 25px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16.8px;
            color: #a1aab0;
          }
        }

        .semi-collapse-header-icon {
          width: 18px;
          height: 18px;
        }
      }

      .semi-collapse-content {
        padding-inline: 44px;
        padding-bottom: 28px;
      }

      .log-container {
        font-weight: 400;
        font-size: 12px;
        line-height: 21.6px;
        color: black;
        background-color: #edf1f4;
        border: 1px solid #e3e7ec;
        border-radius: 4px;
        padding: 16px;

        & p {
          margin-block: 2.4px;
          min-height: 12px;
          line-height: 21.6px;
        }

        .semi-skeleton-paragraph li {
          background-color: var(--v2-color-grey300);
        }
      }
    }

    .semi-collapse-item:has([aria-expanded='true']) {
      background-color: #fafbfc;
    }
  }
}
