.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 600px;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 164px;
  }

  .empty-title {
    font-size: 20px;
    line-height: 20px;
    margin-top: 37px;
  }

  .description {
    margin-top: 10px;
    font-size: var(--semi-font-size-small);
  }

  .btn-get-started {
    margin-top: 50px;
    width: 207px;
    font-size: var(--normal-font-size);
  }
}

.table-about {
  padding: 18px 24px;
  border-radius: var(--semi-input-border-radius);
  font-size: var(--semi-font-size-small);
  border: 1px dashed #EEEEEE;
}

.block-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
