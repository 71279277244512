@import 'src/styles/minix';

.query-result-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;

  .query-result-header-v2 {
    padding: 9.5px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;

    justify-content: space-between;

    .title {
      font-size: var(--normal-font-size);
    }

    .operate-group {
      .semi-button {
        height: 34px;
        padding: 10px 19px;
        @include font(500);
      }

      .btn-cancel {
        margin-left: 11px;
        min-width: 101px;
        background-color: var(--container-background-color);
        color: var(--primary-text-color);
        font-weight: 500;
      }

      .btn-run {
        margin-left: 11px;
        min-width: 101px;
        padding: 10px;
        font-size: var(--semi-font-size-small);

        &.running {
          background: #d1d7dd !important;
        }

        &:not(:disabled) {
          background: #2e363a;
        }

        &:not(:disabled):hover {
          background: var(--semi-color-primary-hover);
        }
      }

      .btn-generate {
        //border-color: var(--semi-color-border);
        border-color: var(--v2-color-grey300);
        color: var(--primary-text-color);
        font-size: var(--semi-font-size-small);

        .icon {
          width: 13px;
        }

        &:disabled {
          border-color: var(--container-background-color);
          color: var(--semi-color-focus-border);

          .icon {
            svg rect,
            svg path {
              stroke: var(--semi-color-focus-border);
            }
          }
        }

        &:not(:disabled):hover {
          border-color: var(--semi-color-link);
          color: var(--semi-color-link);

          .icon {
            svg rect,
            svg path {
              stroke: var(--semi-color-link);
            }
          }
        }
      }
    }
  }

  .query-result-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .auto-wrapper {
      width: 100%;
    }

    .query-result-empty {
      display: flex;
      margin: auto;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .icon {
        width: 123px;
      }

      .desc {
        margin-top: 29px;
        font-size: var(--semi-font-size-small);
        color: var(--primary-text-color);
      }

      .tip {
        margin-top: 10px;
        font-size: var(--semi-font-size-extra-small);
        color: var(--description-text-color);
        white-space: break-spaces;
      }
    }

    .chainbase-table {
      height: 100%;
    }

    .result-wrapper {
      //width: 100%;
      height: 100%;
      overflow-y: scroll;
    }

    .table-pagination {
      padding: 8px 28px 29px 31px;

      .select {
        min-width: 46px;
        margin: 0 8px;
        background: var(--terminal-background-color);
        border: 1px solid var(--semi-color-focus-border);
        height: 23px;

        .semi-select-arrow {
          transform: scale(0.75);
        }
      }

      .semi-col > span {
        @include font(500);
        color: var(--description-text-color);
      }

      .pager {
        display: inline-flex;
        margin-left: 8px;

        .btn {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-color: var(--container-background-color);

          &.btn-next {
            transform: rotate(180deg);
          }

          &:disabled {
            .icon {
              svg path {
                fill: var(--disabled-color);
              }
            }
          }

          .icon {
            width: 4px;
          }
        }
      }
    }
  }

  .query-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 19px;

    > span {
      @include font(500);
      padding: 0 8px;
      font-size: var(--semi-font-size-extra-small);
      border-right: 1px solid var(--second-description-color);
    }

    .latency {
      color: var(--description-text-color);
    }

    .icon-success {
      width: 13px;
    }

    .btn-download {
      .icon {
        width: 11px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  .semi-progress-horizontal {
    margin: 0;
    display: inline-block;

    .semi-progress-track-inner {
      border-radius: 0;
    }

    .semi-progress-line-text {
      margin-top: 8px;
      margin-left: 8px;
      color: #76808a;
      font-size: 14px;
      padding: 4px 8px;
      background: #e3e7ec;
      border-radius: 4px;
      display: inline-block;
      min-width: 50px;
      text-align: center;
      box-sizing: border-box;
      font-weight: 400;
      position: relative;
    }

    .follow {
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      gap: 24px;

      .timer {
        margin: auto 0 auto 8px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        color: #76808a;
      }

      .queue {
        display: flex;
        gap: 4px;

        .text{
          width: 100px;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
}

.semi-portal {
  .table-pagination-dropdown {
    margin-bottom: -20px;
  }

  .semi-radio-cardRadioGroup {
    padding: 0;
  }

  .semi-form {
    display: flex;
    flex-direction: column;
  }

  .semi-form .semi-form-field {  
    &[x-field-id="generationApiType"] {
      padding: 0px 0 0 !important;
    } 
  }
}

.customize-drop-down-arrow {
  -webkit-appearance: none; /* Chrome, Safari, Opera */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* 标准语法 */

  background-image: url('../../../../../../assets/icon/common/select-drop-down-arrow.svg'); /* 添加自定义下拉箭头图标 */
  background-repeat: no-repeat;
  background-position: right 0.5rem top 9px; /* 在右侧显示图标 */
  background-size: 0.5rem;
}

.datacloud-no-project {
  .semi-modal-content.semi-modal-content-animate-show {
    position: relative;
  }
}
