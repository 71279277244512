
.nav-header {
  --nav-header-height: 48px;
  background-color: var(--v2-color-grey600);
  height: var(--nav-header-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;

  .logo, .btn-back {
    width: var(--nav-header-height);
    height: var(--nav-header-height);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--v2-color-line-on-dark);
    }
  }

  .logo {
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  .btn-back {

    .icon {
      display: flex;
      height: 14px;

      svg path {
        stroke: var(--v2-background-color-light);
      }
    }

    &:hover {
      border-right: 1px solid var(--v2-color-line-on-dark);
    }
  }

  .header-breadcrumb.semi-breadcrumb-wrapper {
    padding: 0 18px;

    .semi-breadcrumb-separator {
      color: var(--v2-color-grey500);
    }

    .semi-breadcrumb-item-wrap {
      margin-right: 8px;

      .semi-breadcrumb-item {
        margin-right: 8px;
        .semi-typography {
          font-size: var(--semi-font-size-large);
          color: var(--v2-color-grey500);
        }

        .semi-avatar {
          width: 16px;
          height: 16px;
          border: 1px solid var(--v2-color-line);
          border-radius: var(--semi-border-radius-medium);
          background-color: var(--v2-background-color-light);
        }

        &.semi-breadcrumb-item-active {
          cursor: default;

          .semi-typography {
            color: var(--v2-background-color-light);
          }
        }
      }
    }
  }


}
