
body {
  background-color: #FBFBFB;;
}

.login-container {

  /* variables */
  --login-card-width: 412px;
  --input-width: 341px;
  --input-height: 50px;


  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .btn-logo {
    margin-top: 108px;
    margin-bottom: 44px;

    .logo {
      width: 164px;
    }
  }


  .content {
    flex: 1;
  }

  .footer {
    margin-bottom: 72px;

    p {
      color: #D2D2D2;
      font-size: 14px;
    }
  }

  .card {
    width: var(--login-card-width);

    border: 1px solid #F0F0F0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .semi-form-field-label-required .semi-form-field-label-text::after {
    display: none;
  }

  .input-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;


    .label-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      label {
        color: var(--primary-text-color);
      }
    }

    .semi-form-field {
      padding: 0;
    }

    .semi-form-field[x-label-pos=top] .semi-form-field-label {
      margin-bottom: 12px;
    }

    //input {
    //  margin-top: 7px;
    //  width: var(--input-width);
    //  height: var(--input-height);
    //  background-color: var(--container-background-color);
    //  border: 1px solid var(--container-background-color);
    //  box-sizing: border-box;
    //  border-radius: 9px;
    //  padding: 0 27px;
    //  outline: none;
    //  transition: all 0.35s;
    //
    //  &:focus {
    //    border: 1px solid #D2D2D2;
    //    background-color: #F3F3F3;
    //  }
    //
    //  &:hover {
    //    background-color: #F3F3F3;
    //  }
    //}

    &.error {
      label {
        color: #FF3B3B;
      }
    }
  }

  .btn-enhance {
    width: var(--input-width);
    height: var(--height-control-large);
    margin-top: 32px;
  }

  .semi-form-field[x-label-pos="top"] {
    padding-top: 0;

    .semi-form-field-label {
      margin-bottom: 8px;
    }

    .semi-form-field-main {
      .semi-input-wrapper {
        height: var(--height-control-large);

        input.semi-input {
          height: var(--height-control-large);
          line-height: var(--height-control-large);
        }
      }
    }
  }

  .btn-cancel {
    margin-top: 26px;
    padding: 10px 20px;
    color: var(--primary-text-color);
    font-size: 14px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

}
