//@font-face {
//  font-family: "AndaleMono";
//  src: url("../../../assets/fonts/ANDALEMO.TTF");
//}


.register {

  --label-color: #569EFF;

  --register-button-color: #27BC37;

  display: flex;
  flex-direction: column;
  align-items: center;

  .register-card {
    background-color: #1E1E1E;
    align-items: flex-start;
    padding: 36px;
    box-sizing: border-box;

    .title {
      color: var(--description-text-color);
      font-size: 16px;
    }

    .input-status-step {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      font-family: "AndaleMono", sans-serif;
      font-size: var(--semi-font-size-regular);

      label {
        color: var(--label-color);
      }

      .input-complex {
        display: flex;
        letter-spacing: -1px;
        margin-top: 2px;
        align-items: center;

        .icon {
          width: 14px;
          height: 14px;
        }

        input {
          flex: 1;
          background-color: transparent;
          border: none;
          outline: none;
          color: #FFF;
          font-size: var(--semi-font-size-large);
          line-height: 28px;
          padding: 0 11px;
          margin: 2px 0;
          font-family: "AndaleMono", sans-serif;
        }

        .btn-next {
          border-radius: 4px;
          border: 1px solid var(--register-button-color);
          color: var(--register-button-color);
          padding: 5px;
          font-size: var(--semi-font-size-extra-small);

          &.disabled {
            border: 1px solid #5A5A5A;
            color: #5A5A5A;
          }
        }
      }
    }


    .input-status-step.subscribe {
      label {
        padding-right: 10px;
        white-space: pre-line;
      }
    }

    .privacy {
      margin-top: 30px;
      color: var(--description-text-color);

      a {
        text-decoration: underline;
      }
    }

    .btn-create {
      margin-top: 34px;
      width: var(--input-width);
      height: var(--input-height);
      border: 1px solid var(--register-button-color);
      color: var(--register-button-color);
      background-color: transparent;
    }
  }
}

.to-login {
  margin-top: 44px;
  margin-bottom: 44px;
  display: flex;
  flex-direction: row;
  font-size: var(--semi-font-size-regular);

  span {
    color: var(--primary-text-color);
    margin-right: 10px;
  }

  .btn-login {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-left: 5px;
      height: 12px;
    }
  }
}


.email-activation {
  font-family: "AndaleMono", sans-serif;
  color: #FFF;

  .card {
    .title {
      color: #27BC37;
    }

    p {
      color: #A0A0A0;
      letter-spacing: -1px;
      font-size: 16px;
    }

    .btn-resend {
      font-size: 16px;
      font-family: "AndaleMono", sans-serif;
      color: var(--label-color);
    }
  }
}
