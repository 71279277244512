@import "minix";

*[class^='semi-'] {
  font-family: "Roboto Flex", sans-serif;
}

/* Typography Text */
.semi-typography {
  @include font(500);
  line-height: 1.25;

  &.semi-typography-info {
    color: var(--semi-color-info);
  }
}


.semi-button-size-large {
  height: var(--input-height);
}


.semi-modal {

  .semi-modal-close {

    .icon-close {
      width: 21px;
      height: 21px;
      border-radius: var(--semi-border-radius-extra-small);
    }

    &:hover {
      background-color: transparent;
    }
  }


  &.semi-modal-medium {
    width: 480px;
  }

  &.semi-modal-large {
    width: 540px;
  }

  .semi-modal-content {
    border: none;
    padding: 0 32px 32px;
    box-shadow: 0 11px 24px rgba(0, 0, 0, 0.025377);
  }

  .semi-modal-header {
    margin-top: 29px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--v2-color-line);
    padding-bottom: 8px;

    .semi-typography-h5.semi-typography {
      font-size: 24px;
      line-height: 1.5em;
      @include font(500);
    }
  }

  .semi-modal-footer {
    margin: 32px 0 0;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .semi-button {
        margin: 0;
        width: calc((100% - 8px) / 2);
        height: var(--height-control-large);
        font-weight: 500;

        &[aria-label="cancel"] {
          background-color: var(--v2-color-grey100);
          color: var(--v2-color-grey700);
          border: 1px solid var(--v2-color-grey300);
          font-weight: 400;
        }

        &.semi-button-danger[aria-label="confirm"] {
          background-color: #FFE4E4;
        }
      }
    }
  }
}

.semi-button {
  @include font(400);
  height: var(--height-control-default);

  &.semi-button-size-small {
    height: var(--height-control-small);

    &.semi-button-with-icon-only {
      width: var(--height-control-small);
    }
  }

  &.semi-button-size-large {
    height: var(--height-control-large);
    padding: 10px 16px;

    &.semi-button-with-icon {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.semi-form .semi-form-field {

  &[x-label-pos="top"] {
    padding: 32px 0 0;
  }

  .semi-form-field-label {
    line-height: var(--semi-font-size-regular);
    margin-bottom: 8px;

    .semi-form-field-label-text {
      &:after {
        display: none;
      }
    }
  }

  .semi-form-field-main {
    .semi-input-textarea-wrapper {
      border: 1px solid var(--v2-color-line);
      background-color: var(--v2-color-grey50);

      &:hover {
        background-color: var(--v2-color-grey100);
      }
    }

    .semi-select {
      .semi-select-selection {
        border-right: 0;
        justify-content: flex-start;
      }
    }
  }

  .semi-input-wrapper {
    transition: all 0.25s;

    &:not(.semi-input-wrapper-error) {
      border: 1px solid var(--v2-color-line);
      background-color: var(--v2-color-grey50);

      &:hover {
        background-color: var(--v2-color-grey100);
      }

      &.semi-input-wrapper-focus {
        background-color: #FFF;
        border-color: var(--v2-color-grey600);
      }
    }

    &.semi-input-wrapper-large {
      height: var(--height-control-large);

      .semi-input.semi-input-large {
        font-size: var(--semi-font-size-regular);
        height: 100%;
      }
    }
  }
}


.semi-select {
  height: var(--height-control-large);
  background-color: var(--v2-color-grey50);
  border: 1px solid var(--v2-color-line);

  &:hover, &:active, &:focus {
    border: 1px solid var(--v2-color-line);
  }

  .semi-select-selection {
    font-size: var(--semi-font-size-regular);
  }


  &.semi-select-open {
    .semi-select-arrow {
      transform: rotate(180deg);
    }
  }

  .semi-select-arrow {
    width: 28px;
    font-size: var(--semi-font-size-extra-small);
    color: var(--primary-text-color);
    transition: transform 0.2s ease-in-out;

    svg path {
      fill: #000
    }
  }

  .semi-select-selection-placeholder {
    color: inherit;
  }
}

.semi-form-vertical .semi-form-field {
  padding-top: 14px;
  padding-bottom: 14px;
}


.semi-typography-assist {
  color: var(--semi-color-assist);
}

.semi-list {
  &.semi-list-bordered {
    border-radius: var(--semi-border-radius-small);
  }
}


.full-container {
  .semi-portal {
    position: absolute;
  }
}

.semi-portal {
  position: fixed;

  .semi-dropdown-wrapper {
    overflow: hidden;
    border: 1px solid var(--v2-color-line);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .semi-dropdown {
      .semi-dropdown-menu {
        padding: 0;

        .semi-dropdown-item {
          min-width: 46px;
          font-size: var(--semi-font-size-small);
          height: var(--height-control-small);
          line-height: var(--semi-font-size-regular);
          padding: 0 8px;
          border-bottom: 1px solid var(--v2-color-line);
          --semi-color-text-0: var(--v2-color-grey500);

          &:hover {
            color: var(--v2-color-blue400);
            background-color: var(--v2-color-grey100);
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}


.semi-toast-content {
  padding: 14px 19px;
  box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.05);
  border-radius: var(--semi-border-radius-small);

  .icon {
    width: 15px;
    height: 15px;
    margin: auto;
  }

  .semi-toast-content-text {
    @include font(400);
    font-size: 13px;
    line-height: 18px;
    margin-left: 13px;
    margin-right: 0;
  }
}


.semi-sidesheet {

  .semi-sidesheet-header {
    padding: 32px 0 8px;
    margin: 0 32px 16px;
    border-bottom: 1px solid var(--container-background-color);

    .semi-sidesheet-title {
      margin-top: 0;
      font-size: 24px;
      line-height: 24px;
    }
  }
}


.semi-table-wrapper {
  .semi-table-bordered {
    .semi-table-container {
      border-right: 1px solid var(--semi-color-border);
      border-radius: var(--semi-border-radius-medium);
    }

    .semi-table-thead > .semi-table-row > .semi-table-row-head {
      border-right: 0;
      border-bottom: 1px solid var(--v2-color-line);
      background-color: var(--v2-color-grey200);
      color: var(--v2-color-grey700);
      font-size: var(--semi-font-size-small);
      line-height: var(--semi-font-size-regular);
      box-sizing: border-box;
      text-transform: capitalize;
    }

    .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
      border-right: 0;
      font-size: var(--semi-font-size-regular);
      color: var(--v2-color-grey700);
    }
  }
}

.semi-tabs {
  .semi-tabs-bar {
    display: flex;
    align-items: center;

    .semi-tabs-bar-extra {
      margin-left: auto;
    }

    .semi-tabs-tab {
      @include font(500);
      position: relative;
      padding: 12px 0;
      border-bottom: 0;
      color: var(--description-text-color);
      line-height: 1.25;

      &.semi-tabs-tab-active {
        @include font(600);
        color: var(--primary-text-color);
      }

      &-active, &:hover {
        border-bottom: none;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &-active.semi-tabs-tab {
        &:after {
          opacity: 1;
          background-color: var(--primary-text-color);
        }
      }

      &:after {
        display: block;
        opacity: 0;
        transition: all 0.35s ease-in-out;
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: var(--container-background-color);
      }
    }
  }
}

.semi-banner {
  .semi-typography {
    color: inherit;
  }
}


.semi-avatar-group {
}

.semi-avatar {
  > img {
    cursor: default;
  }
}

.semi-popover {
  .semi-select-option-list {
    &.semi-select-option-list-chosen[aria-multiselectable="true"] {
      .semi-select-option {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        .semi-select-option-icon {
          opacity: 0;
          display: flex;
        }

        &-selected {
          background-color: var(--v2-color-grey100);

          .semi-select-option-icon {
            opacity: 1;
            color: #000;
          }
        }
      }


    }
  }
}
