.chainbase-solid-toast {
  .semi-toast-content {
    padding: 13px 17px;
    box-sizing: border-box;
    width: 360px;

    border: 1px solid #5dbd5b;
    background-color: #e9ffe9;

    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
  }

  .semi-toast-close-button {
    margin: 0;
    width: 16px;
    height: 16px;

    .semi-button {
      display: flex;
      margin-left: 40px;
      padding: 0;
      width: 16px !important;
      height: 16px !important;

      .semi-icon svg path {
        fill: #5DBD5B;
      }
    }
  }
}
