.integration-selector {
  .input-search {
    background-color: #fff;
    width: 368px;
    border: 1px solid var(--v2-color-grey300);
  }

  .filter-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #76808a;

    --semi-color-primary: #3089d2;
    --semi-color-primary-hover: #3089d2;
    --semi-color-primary-active: #3089d2;

    .semi-checkbox-addon {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #76808a;
    }
  }

  .integration-types {
    .semi-col {
      margin-block: 8px;

      .integration-item {
        height: 100%;
        background: #ffffff;
        border: 1px solid #e3e7ec;
        border-radius: 4px;
        overflow: hidden;
        transition: all 100ms;
        cursor: pointer;

        .first-row {
          height: 80px;
          background-color: #edf1f4;

          display: flex;
          justify-content: start;
          align-items: center;

          padding: 0 16px;
        }

        .second-row {
          padding: 16px;
          color: #a1aab0;
          font-weight: 400;
          font-size: 14px;
          line-height: 19.6px;
        }

        &:not(.coming-soon) {
          &:hover {
            .icon-enter {
              visibility: visible;
            }
          }
        }

        .icon {
          width: 48px;
          height: 48px;
          border: 1px solid #e3e7ec;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 16px;
        }

        .name {
          margin-right: 16px;
          font-size: 14px;
          color: #191e21;
        }

        &:hover {
          .status {
            visibility: visible;
          }
        }

        .status {
          visibility: hidden;

          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.011em;
          text-align: left;
          color: #ffffff;

          width: 87px;
          height: 20px;
          top: 14px;
          left: 116px;
          border-radius: 54px;
          background-color: black;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon-enter {
          transition: all 100ms;
          visibility: hidden;
          margin-left: auto;
          width: 16px;
          height: 16px;
        }

        &.coming-soon {
          cursor: not-allowed;
        }
      }
    }
  }

  .btn-request {
    margin-top: 16px;
    background-color: #ffffff;
    color: var(--v2-color-grey900);
    font-weight: 600;
    border: 1px solid var(--v2-color-grey300);

    &:hover {
      border: 1px solid var(--v2-color-grey300);
      background-color: var(--v2-color-grey50);
    }
  }
}

.request-integration-modal {
  .semi-button {
    font-weight: 500;
  }
}

.integration-configuration-modal {
  .semi-modal-wrap {
    display: flex;
    align-items: center;
  }

  .semi-modal {
    margin-block: 0;
    width: fit-content !important;
    height: fit-content !important;

    .semi-modal-content {
      padding: 0;
      width: 1000px;
      background-color: white;
      outline: none;

      .semi-modal-header {
        margin: 0;
        padding: 32px 32px 24px;
      }
    }
  }

  .integration-configuration-container {
    .configuration-card {
      border: 0;
    }
  }
}
