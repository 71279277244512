.integration-creation-container {
  display: flex;
  flex-direction: column;

  .new-integration-selector-cotainer {
    padding: 24px 32px;
  }

  .content-wrapper {
    height: calc(100dvh - 160px);
    padding: 24px;

    .left-wrapper {
      overflow: scroll;
    }
    
    .right-wrapper {
      overflow: scroll;
    }
  }

  .loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    
  }
}
