@import '../../styles/minix';

.navigation {
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #191e21;

  box-sizing: border-box;

  &::-webkit-scrollbar {
    position: absolute;
    padding-right: 5px;
    right: -10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 31px;
    background-color: #2E363A;
    border: 5px solid #191E21;
  }

  &::-webkit-scrollbar-track {
    background-color: #191E21;
    padding: 4px;
  }

  &.settings-navigation {
    width: 256px;
    padding: 32px 16px;
    box-sizing: border-box;
    background-color: transparent;
    border-right: 1px solid var(--v2-color-line);

    .navigation-list {
      .navigation-item {
        color: var(--v2-color-grey400);
        .hover-subfix {
          display: none;
        }
        &:hover {
          .hover-subfix {
            display: flex;
          }
        }

        .icon {
          svg rect,
          svg circle {
            stroke: var(--v2-color-grey400);
          }

          &.billing svg path {
            fill: transparent;
            stroke: var(--v2-color-grey400);
          }
          &.account svg path {
            fill: transparent;
            stroke: var(--v2-color-grey400);
          }
          &.detail svg path {
            fill: var(--v2-color-grey400);
            stroke: transparent;
          }
          &.lock svg path {
            fill: var(--v2-color-grey400);
            stroke: transparent;
          }
        }
        &:not(.disabled):hover {
          background-color: var(--v2-color-grey100);
        }

        &.active {
          @include font(600);
          color: var(--v2-color-grey700);
          background-color: var(--v2-color-grey100);

          .icon {
            svg rect,
            svg circle,
            svg path {
              stroke: var(--v2-color-grey700);
            }

            svg path {
              fill: transparent;
            }

            &.detail svg path {
              fill: var(--v2-color-grey700);
              stroke: transparent;
            }
            &.lock svg path {
              fill: var(--v2-color-grey700);
              stroke: transparent;
            }
          }
        }
      }
    }
  }

  .btn-back {
    margin-top: 30px;
    padding: 4px 0 4px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--semi-font-size-small);
    color: var(--v2-color-grey400);
    align-self: flex-end;

    .icon svg {
      height: 10px;
    }

    .semi-button-content-right {
      margin-left: 12px;
    }

    &:hover {
      background-color: initial;
      color: var(--v2-color-grey600);

      .icon svg path {
        stroke: var(--v2-color-grey600);
      }
    }
  }

  .logo-link {
    padding: 28px;
    .logo {
      width: 137px;
    }
  }

  .beta {
    width: 42px;
    margin-left: 8px;
  }

  .navigation-list {
    flex: 1;
    display: flex;
    flex-direction: column;

    .navigation-group {
      padding: 10px 16px;

      .title {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0 18px 12px;

        color: '#76808A';
        font-weight: 400;
        font-size: 12px;
      }
    }

    h3 {
      color: var(--v2-color-grey500);
      font-size: var(--semi-font-size-small);
      line-height: var(--semi-font-size-regular);
      height: var(--semi-font-size-regular);
      padding-left: 17px;
      margin-bottom: 12px;
    }

    .navigation-group-api {
      .navigation-item {
        &.active {
          .icon {
            color: #aa87f6;
            svg path {
              fill: #aa87f6;
            }
          }
        }
      }
    }

    .navigation-group-datacloud {
      .navigation-item {
        &.active {
          .icon {
            color: #3abb94;
            svg path {
              fill: #3abb94;
            }
          }
        }
      }
    }

    .navigation-group-dataInfura {
      .navigation-item {
        &.active {
          .icon {
            color: #70a5ff;
            svg path {
              fill: #70a5ff;
            }
          }
        }
      }
    }

    .navigation-item {
      width: 100%;
      padding: 12px 16px;
      height: var(--height-control-large);
      background-color: transparent;
      transition: all 0.3s ease;
      color: var(--v2-color-grey300);
      border-radius: var(--semi-border-radius-medium);
      box-sizing: border-box;
      margin-bottom: 2px;
      position: relative;

      &.navigation-item-subdirectory {
        padding-left: 44px;
      }

      .navigation-item-title-subfix {
        margin-left: 5px;
      }

      .collapse-arrow {
        position: absolute;
        right: 14px;
        transform: rotate(90deg);
        transition: transform 0.25s ease;

        &.expanded {
          transform: rotate(270deg);
        }
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      .hover-subfix {
        display: none;
      }
      &:hover {
        .hover-subfix {
          display: flex;
        }
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 12px;

        svg path {
          fill: var(--v2-color-grey400);
        }
      }

      &.active {
        background-color: var(--v2-color-line-on-dark);
        color: #fff;

        .icon {
          color: #aa87f6;
          svg path {
            fill: #aa87f6;
          }
        }
      }

      &:not(.disabled):hover {
        background-color: var(--v2-color-line-on-dark);
      }

      &.disabled {
        cursor: not-allowed;

        > * {
          opacity: 0.3;
        }

        .soon {
          width: 38px;
          margin-left: 13px;
          opacity: 1;
        }
      }
    }
  }

  .navigation-link-group {
    // border-top: 1px solid var(--v2-color-line-on-dark);
    padding: 8px 8px;

    display: flex;

    .semi-typography-link {
      padding: 0 12px;
      height: var(--height-control-extra-large);
      display: inline-flex;
      align-items: center;
      border-radius: var(--semi-border-radius-medium);

      .semi-typography-link-text {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      a {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 100%;
        @include font(400);
        color: var(--v2-color-grey300);
      }

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg path {
          fill: var(--v2-color-grey400);
        }
      }

      &:not(.disabled):hover {
        background-color: var(--v2-color-line-on-dark);
      }
    }
  }

  .bottom-group {
    width: 240px;
    position: sticky;
    bottom: 0;

    background-color: #22282c;
  }
}

.semi-portal .semi-popover-wrapper.menu-popover {
  padding: 8px 0;
  .menu {
    .btn-menu-item {
      color: var(--v2-color-grey400);
      background-color: transparent;
      height: var(--height-control-large);
      padding: 0 18px;
      min-width: 124px;
      text-align: left;
      justify-content: flex-start;

      .icon {
        svg path {
          fill: var(--v2-color-grey400);
        }
      }

      &:hover {
        background-color: transparent;
        color: #fff;

        .icon {
          svg path {
            fill: #fff;
          }
        }
      }
    }
  }
}
