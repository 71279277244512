.btn-operation {
  border: 1px solid var(--v2-color-line);
  background-color: var(--v2-background-color-light);
  box-sizing: border-box;
  transition: all 0.25s;

  .icon {
    width: 14px;
    height: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    // background-color: #FFF !important;
    border: 1px solid var(--v2-color-grey100) !important;

    .icon {
      svg circle {
        fill: var(--v2-color-blue400);
      }
    }
  }

  &:active {
    // border: 1px solid var(--v2-color-blue400) !important;
    background-color: var(--v2-color-blue400) !important;;

    .icon {
      svg circle {
        fill: var(--v2-background-color-light);
      }
    }
  }
}
