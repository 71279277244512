.jobs-overview {
  &-header {
    background-color: white;
    height: 72px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 32px;

    & h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    & p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16.8px;
      color: #a1aab0;

      margin-top: 4px;
    }

    & .tail {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      & .doc-link {
        display: flex;
        gap: 4px;
        color: gray;

        font-weight: 400;
        font-size: 14px;
      }

      & .new-job-button {
        height: 36px;

        & .semi-button-content {
          display: flex;
          gap: 8px;
          justify-content: center;
          align-items: center;

          padding: 16px 10px;
        }

        & .dynamic-svg-icon {
          width: 16px;
          height: 16px;
        }

        font-weight: 500;
        font-size: 14px;
        line-height: 16.41px;
      }
    }
  }

  &-divider {
    border-top: 1px solid #e3e7ec;
  }

  &-table-warpper {
    margin-top: 24px;
    margin-inline: 32px;

    .semi-table-container {
      background-color: #FFF;
    }

    & .dataset {
      display: flex;
      align-items: center;
      gap: 8px;

      & .dataset-icon {
        width: 18px;
      }
    }

    & .integration {
      display: flex;
      align-items: center;
      gap: 8px;

      & .integration-icon {
        border: 1px solid #e3e7ec;
        border-radius: 4px;
        width: 24px;
      }
    }

    & .switch {
      width: 32px;
      height: 16px;
      vertical-align: sub;

      &.semi-switch-checked {
        background-color: #3089d2;
      }

      & .semi-switch-knob {
        width: 10px;
        height: 10px;
      }
    }

    & .setting-button {
      width: 16px;
      height: 16px;
      padding: 0;
      vertical-align: sub;
    }
  }

  &-pagination {
    margin-top: 16px;
    margin-left: 33px;
    margin-right: 31px;

    display: flex;
    justify-content: space-between;

    & .job-count {
      &-text {
        font-weight: 400;
        font-size: 12px;
      }

      &-amount {
        font-weight: 800;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
}
