
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .overlay {
    z-index: 3;
    position: absolute;
    background: var(--semi-color-overlay-bg);
    will-change: opacity;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}
