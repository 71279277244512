@import "src/styles/minix";

.sql-editor-tabs.semi-tabs {

  height: 100%;

  display: flex;
  flex-direction: column;

  .semi-tabs-bar {
    display: flex;
    padding-top: 20px;
    overflow: hidden;

    .semi-tabs-tab {
      @include font(500);
      padding: 8px 31px 12px;
      position: relative;
      border: none;
      font-size: var(--semi-font-size-extra-small);
      top: 2px;
      filter: drop-shadow(1px 0px 0px #636D77);

      margin-right: 7px;
      color: #A8B3B7;
      background: linear-gradient(90deg, #444D56 18.38%, #3A434D 91.91%);;


      &:before {
        content: '';
        display: block;
        width: 20%;
        height: 105%;
        right: -10px;
        z-index: -1;
        top: 0;
        transform: skew(20deg);
        position: absolute;
        background: #3A434D;
        border-top-right-radius: var(--semi-border-radius-small);
      }

      &:after {
        display: none !important;
      }

      &.semi-tabs-tab-active {
        @include font(500);
        background: var(--editor-active-color);
        color: var(--container-background-color);

        &:before {
          background: var(--editor-active-color);
        }

        .semi-tabs-tab-icon-close {
          svg path {
            stroke: #FFF !important;
          }
        }
      }

      .semi-tabs-tab-icon-close {
        position: absolute;

        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        padding: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .close-wrapper-v2 {
      padding-left: 5px;
      height: 100%;
      display: flex;
      align-items: center;

      .semi-button {
        padding: 0;

        &.btn-save {
          padding: 0 20px;
          font-size: var(--semi-font-size-small);
          line-height: var(--semi-font-size-small);
        }

        &.btn-more {
          &:hover {
            .icon {
              svg circle {
                fill: var(--semi-color-link)
              }
            }
          }
        }

        &.btn-add {
          margin-left: 6px;
          width: 17px;
          height: 17px;

          svg path {
            fill: #000;
          }
        }
        &.btn-add:hover {
          border-radius: 4px;
          background-color: #EDF1F4;

        }

        &.btn-save:not(:disabled):hover {
          color: var(--semi-color-link);
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    .semi-tabs-bar-extra {
      margin-left: auto;
      margin-right: 30px;
    }

  }

  .semi-tabs-bar-top-v2 {
    padding-top: 8px;
    font-size: 12px;
    border-bottom: 1px solid #444D56;
  }

  .semi-tabs-content {
    height: 100%;
    padding: 0;
    overflow: hidden;

    .semi-tabs-pane {
      height: 100%;
      background-color: var(--editor-active-color);
    }
  }
}


.unsave-modal.tip-modal {

  .semi-modal .modal-tip-content {
    .icon {
      width: 119px;
      height: 100px;
      object-fit: contain;
    }
  }

}
