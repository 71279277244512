.table-navigation.data-explorer-tabs-container {
  position: relative;

  .semi-portal-inner {
    width: calc(100% - 48px);
  }

  .table-type-tabs {
    & > .semi-tabs-content {
      height: 100%;
      overflow: auto;
    }

    & > .semi-tabs-content::-webkit-scrollbar {
      display: block;
      width: 8px;
    }
    
    & > .semi-tabs-content::-webkit-scrollbar-thumb {
      background-color: #D1D7DD;
      border-radius: 4px;
    }
  }
}
