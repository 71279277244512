
.archived-queries-modal {

  .editor-params-wrapper {
    overflow: hidden;
    margin-bottom: 5px;
  }

  .sql-statement {
    min-height: 200px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 12px;
    border: 3px solid var(--container-background-color);
    font-size: var(--semi-font-size-small);
    color: var(--description-text-color);
    border-radius: var(--semi-border-radius-small);

    > div {
      padding: 19px;
      white-space: break-spaces;
    }
  }

  .semi-modal .semi-modal-footer-flex {
    margin-top: 0;
  }
}
