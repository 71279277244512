
.pagination {
  .semi-page-item {
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    font-size: var(--semi-font-size-small);
    margin: 0 4px 0 0;

    &.semi-page-item-active {
      background-color: var(--v2-color-grey200);
      color: var(--v2-color-blue400);
    }


    &.semi-page-next,
    &.semi-page-prev {
      border: 1px solid #E3E6E8;
      background-color: var(--v2-background-color-light);

      &:not(.semi-page-item-disabled):hover {
        background: #EDEFF0;
      }

      &.semi-page-item-disabled {
        .icon {
          svg path {
            stroke: var(--v2-color-grey300);
          }
        }
      }

      .icon {
        width: 4px;

        svg path {
          stroke: var(--v2-color-grey700);
        }
      }

      &.semi-page-next {
        .icon {
          transform: rotate(180deg);
        }
      }
    }

  }

  .semi-page-quickjump {
    font-size: var(--semi-font-size-small);

    .semi-input-wrapper {
      background-color: #FFF;
      border: 1px solid #E3E6E8;
      display: inline-flex;
      line-height: initial;
      height: initial;
      width: 33px;

      .semi-input {
        height: 24px;
        line-height: 24px;
        font-size: var(--semi-font-size-small);
        border-radius: 4px;
        text-align: center;
        padding: 0;
      }
    }
  }

  .semi-page-switch {
    margin-left: 15px;

    .semi-select {
      border-radius: 4px;
      height: 24px;
      border: 1px solid var(--v2-color-line);
      background-color: var(--v2-background-color-light);

      .semi-select-selection {
        font-size: var(--semi-font-size-small);
      }
    }

  }

}
