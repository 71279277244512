.empty-job-warpper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.empty-job-title {
  margin-top: 56px;

  font-weight: 500;
  font-size: 24px;
  line-height: 33.6px;
  text-align: center;

  .first-word {
    color: #9083e2;
  }
}

.empty-job-main-warpper {
  position: relative;
  --scale-radio: 1;

  @media screen and (min-width: 1560px) {
    --scale-radio: 1.083;
  }

  @media screen and (min-width: 1920px) {
    --scale-radio: 1.333;
  }

  @media screen and (min-width: 2560px) {
    --scale-radio: 1.778
  }

  width: calc(750px * var(--scale-radio));
  height: calc(437px * var(--scale-radio));

  margin-block: calc(24px * var(--scale-radio));
  margin-inline: auto;

  .empty-job-animation {
    position: absolute;
    top: 0;
    z-index: 50;
  }

  .empty-job-main {
    position: relative;
    height: 100%;

    &-list {
      &.datasets {
        position: absolute;
        top: calc(93px * var(--scale-radio));
        left: calc(51px * var(--scale-radio));

        .list-item {
          margin-top: calc(18px * var(--scale-radio));
        }
      }

      &.integrations {
        position: absolute;
        top: calc(6px * var(--scale-radio));
        left: calc(622px * var(--scale-radio));

        .list-item {
          margin-top: calc(12px * var(--scale-radio));
        }

        h2 {
          margin-bottom: calc(14px * var(--scale-radio));
        }
      }

      & h2 {
        font-weight: 600;
        font-size: calc(14px * var(--scale-radio));
        line-height: calc(19.6px * var(--scale-radio));
        margin-left: calc(1px * var(--scale-radio));
      }

      & ul {
        list-style: none;
      }

      & .list-item {
        display: flex;
        justify-content: start;
        align-items: start;

        & .icon-warpper {
          box-sizing: border-box;
          width: calc(37px * var(--scale-radio));
          height: calc(37px * var(--scale-radio));

          fill: #fff;
          filter: drop-shadow(0px 6px 10px #04375c40)
            drop-shadow(0px 3px 0px #e7f0f4);
          background-color: white;
          border-radius: calc(5px * var(--scale-radio));

          padding: calc(8px * var(--scale-radio));

          &.abstracted-data {
            padding: calc(7px * var(--scale-radio))
              calc(3px * var(--scale-radio)) calc(5px * var(--scale-radio))
              calc(3px * var(--scale-radio));
          }
        }

        &-icon {
          width: calc(21px * var(--scale-radio));
          height: calc(21px * var(--scale-radio));

          &.abstracted-data {
            width: 100%;
            height: 100%;
          }

          &.on-left {
            order: 0;
          }

          &.on-right {
            order: 1;
          }
        }

        &-text {
          font-weight: 400;
          font-size: calc(12px * var(--scale-radio));
          line-height: 100%;

          text-align: center;

          margin-top: calc(12px * var(--scale-radio));
          margin-left: calc(7px * var(--scale-radio));
        }
      }
    }

    & .arrow-group {
      position: absolute;
      left: calc(157px * var(--scale-radio));
      top: calc(50px * var(--scale-radio));
      width: calc(451px * var(--scale-radio));
    }

    & .logo-warpper {
      position: absolute;
      padding: calc(22px * var(--scale-radio));

      fill: #fff;
      filter: drop-shadow(0px 8px 20px rgba(4, 55, 92, 0.25))
        drop-shadow(0px 5px 0px #f2f7f9);
      background-color: white;
      border-radius: 5px;

      top: calc(178px * var(--scale-radio));
      left: calc(325px * var(--scale-radio));

      & .logo {
        width: calc(56px * var(--scale-radio));
        height: calc(56px * var(--scale-radio));
      }
    }

    & .chainbase-logo {
      width: calc(100px * var(--scale-radio));
      left: calc(325px * var(--scale-radio));
      top: calc(195px * var(--scale-radio));
    }
  }
}

.empty-job-footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 54px;

  .empty-job-create-button {
    width: 156px;
    height: 36px;
  }

  .empty-job-more-info {
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.8px;
    text-align: center;

    .prev-words {
      color: #a1aab0;
    }
  }
}
