
.btn-action {
  margin-right: 6px;
  padding: 8px 10px 8px 8px;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out;
  word-break: keep-all;

  &.button:hover {
    background-color: var(--semi-link-button-fill);
    border-radius: var(--semi-border-radius-extra-small);
  }
}
