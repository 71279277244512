.sync-new-job-setup-integration {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  margin-bottom: auto;

  .semi-tabs .semi-tabs-bar .semi-tabs-tab-active.semi-tabs-tab:after {
    background-color: rgba(48, 137, 210, 1);
    height: 2px;
  }

  .semi-tabs-bar.semi-tabs-bar-line.semi-tabs-bar-top {
    margin-bottom: 24px;
  }

  .semi-radio.radio {
    .semi-radio-inner-checked {
      .semi-radio-inner-display {
        border-color: #3089d2;
        background-color: white;

        circle {
          fill: #3089d2;
        }
      }
    }
  }

  .setup-exists-table {
    background-color: white;

    .table-col-type {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;

      .icon {
        width: 24px;
        height: 24px;
        border: 1px solid #e3e7ec;
        border-radius: 5px;
      }
    }

    .table-col-datasets {
      display: flex;

      .datasets-tag {
        background-color: #e3e7ec;
        width: 28px;
        height: 20px;
        border-radius: 54px;
        color: #a1aab0;
        font-weight: 500;
        font-size: 12px;
      }

      .tag-list {
        display: inline-block;

        color: #a1aab0;
        font-weight: 400;
        font-size: 14px;

        max-width: 240px;
        margin-left: 8px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .single-tag {
          margin-left: 2px;
        }

        .single-tag:not(:last-child)::after {
          content: ',';
        }
      }
    }

    .table-col-job {
      display: flex;
      gap: 8px;

      .tag {
        box-sizing: border-box;
        width: 57px;
        height: 24px;
        padding: 4px 8px;
        border-radius: 4px;

        color: white;

        .semi-tag-content {
          justify-content: start;
          gap: 4px;
        }
      }

      .tag-in-progress {
        background-color: #3089D2;
      }

      .tag-success {
        background-color: #5DBD5B;
      }

      .tag-warning {
        background-color: #EE9F2A;
      }

      .tag-no-job {
        background-color: #A1AAB0;
        font-size: 10px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .table-name-input {
    --semi-color-fill-0: #fafbfc;

    .semi-input.semi-input-default {
      border: 1px solid #e3e7ec;
      border-radius: 4px;
    }
  }

  .semi-tabs {
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .semi-tabs-content {
      max-height: 100%;
      overflow: hidden;

      .sync-tab-pane.semi-tabs-pane-active {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        outline: none;

        .semi-tabs-pane-motion-overlay {
          max-height: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 0;
          overflow: hidden;

          .integrations-table {
            max-height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
          }
        }
      }
    }
  }

  .semi-tabs.semi-tabs-top {
    .semi-tabs-tab {
      padding-bottom: 5px;
    }
  }

  .tab {
    display: flex;
    gap: 8px;

    padding-left: 2px;
    padding-right: 12px;
  }
}
