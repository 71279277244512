.parameter-editor-input {
  border-width: 1px;
  border-color: rgb(227, 230, 232);

  .semi-select-single {
    height: 33px;
  }
}

.parameter-editor-selector {
  height: 33px;
  .semi-select-selection {
    justify-content: start;
  }
}
