.integration-detail-container {
  display: flex;
  flex-direction: column;

  .new-integration-selector-cotainer {
    padding: 24px 32px;
  }

  .integration-edit-page-tabs-container {
    flex-grow: 1;
    margin: 24px 32px;

    .semi-tabs-content.semi-tabs-content-top {
      padding-block: 16px;
    }

    .sync-table-container {
      margin: 0;
    }

    .pagination-warpper {
      margin: 16px 0;
    }

    .semi-tabs-tab {
      padding: 0 !important;

      fill: var(--description-text-color);
      stroke: var(--description-text-color);

      &.semi-tabs-tab-active {
        fill: var(--primary-text-color);
        stroke: var(--primary-text-color);
      }

      .tab {
        padding: 2px 5.83px 5px 7.5px;

        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          width: 16px;
          height: 16px;
        }

        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 19.6px;
        }
      }

      &.semi-tabs-tab-active::after {
        background-color: #3089d2 !important;
        height: 2px;
      }
    }

    .semi-tabs {
      height: 100%;
      display: flex;
      flex-direction: column;

      .semi-tabs-content {
        flex-grow: 1;

        .semi-tabs-pane {
          height: 100%;

          .semi-tabs-pane-motion-overlay {
            height: 100%;
          }
        }
      }
    }

    .integration-configuration-container {
      height: 100%;
      overflow: scroll;

      .card {
        border: 1px solid #e3e7ec;
      }
    }
  }

  .configuration-wrapper {
    .delete-button-wrapper {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;

      .bottom-button-item {
        width: 120px;
        height: 36px;
      }

      .delete-button {
        width: 120px;
        height: 36px;

        border: 1px solid #e3e7ec;
        background-color: #fafbfc;

        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;

        &:hover {
          background-color: #f6f8fa;
          border-color: #d1d7dd;
        }

        &:active {
          background-color: #edf1f4;
          border-color: #a1aab0;
        }
      }
    }
  }
}
