@import "src/styles/minix";

.table-type-tabs {
  .semi-tabs-content {
    padding: 0;
    flex: 1;
    overflow-y: auto;
  }
}

.table-tree {
  &.loading-wrapper {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-empty-default {
    margin-top: 20px;
  }

  .semi-tree-option-list {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    .semi-tree-option-label-empty {
      cursor: default;
    }

    .semi-tree-option {
      padding-top: 0;
      padding-bottom: 0;
      user-select: none;
      --tree-padding-left: 12px;
      background-color: transparent;
      margin: 0 12px;
      border-radius: 4px;

      &:not(.semi-tree-option-empty):hover {
        background-color: #F6F8FA;
      }
      .icon-expand {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s ease-in-out;

        &.table.active {
          transform: rotate(90deg);
        }
      }

      @mixin tree-option-level($levels) {

        @for $i from 1 through $levels {
          &.semi-tree-option-level-#{$i} {
            padding: 0 4px 0 calc(var(--tree-padding-left) * #{$i});
          }
        }

      }

      @include tree-option-level(4);

      &.semi-tree-option-selected {
        background-color: transparent;

        &:hover {
          background-color: var(--semi-color-fill-0);
        }
      }

      &:hover {
      }
    }
  }

  .table-item {
    width: 100%;
    //padding: 3px 0;
    //box-sizing: border-box;
    height: var(--height-control-default);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--v2-color-grey700);
    font-size: var(--semi-font-size-regular);
    line-height: var(--semi-font-size-large);

    .icon-type {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 8px;

      &.logo {
        border: 1px solid var(--v2-color-line);
        background-color: var(--v2-background-color-light);
        border-radius: var(--semi-border-radius-small);
      }
    }

    .name {
      display: inline-grid;

      .text-ellipsis {
        width: 100%;
      }
    }

    .blockchain {
      margin: 0 5.5px 0 0;
      word-break: initial;
      color: var(--semi-color-info);
    }

    .operate-btn {
      padding: 8px 6px;

      .icon {
        width: 16px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      .operate-btn {
        svg rect {
          stroke: var(--v2-color-grey700);
        }

        &:hover {
          svg rect,
          svg path {
            stroke: var(--semi-color-link);
          }
        }
      }
    }
  }

  .semi-tree-option-level-1 + .semi-tree-option-level-2.column {
    .table-column {
      overflow: hidden;
    }
  }

  .semi-tree-option-level-2 + .semi-tree-option-level-3 {
    overflow: hidden;
  }

  .table-column {
    width: 100%;
    padding: 9px 0 9px 28px;
    height: calc(var(--height-control-default) - 2px);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--v2-color-grey400);
    position: relative;

    &:before {
      position: absolute;
      display: block;
      content: ' ';
      width: 1px;
      height: 100%;
      background-color: var(--border-color-tertiary);
      left: 8px;
      top: -80%;
    }

    &:after {
      position: absolute;
      display: block;
      content: ' ';
      width: 14px;
      height: 14px;
      left: 8px;
      bottom: 14px;
      border-left: 1px solid var(--border-color-tertiary);
      border-bottom: 1px solid var(--border-color-tertiary);
      border-bottom-left-radius: 12px;
    }

    .name {
      flex-shrink: 0;
    }

    .column-type {
      margin-left: 8px;
      font-size: var(--semi-font-size-small);
      line-height: var(--semi-font-size-large);
      color: var(--semi-color-info);
      background-color: #EFF3F9;
      border-radius: 2px;
      padding: 0 4px;
      max-width: 150px;
    }
  }

  &.skeleton {
    padding: 8px 0;

    .table-item {
      padding-left: 24px;

      .icon-expand {
        width: 16px;
        height: 16px;
      }

      .name {
        width: 100px;
      }
    }
  }
}

.group-breadcrumb {
  .semi-breadcrumb-item {
    display: flex;
    color: var(--v2-color-grey900);
    @include font(600);


    .semi-typography {
      @include font(600);
    }

    &.semi-breadcrumb-item-active {
      color: var(--v2-color-grey500);
    }

    .semi-breadcrumb-item-title {
      flex-direction: row;
      align-items: center;
    }
  }
}

.table-description-popover {
  padding: 0;

  .table-description-popover-content {
    // border: 1px solid #CACDD0;
    width: 300px;

    .info {
      padding-bottom: 9px;
      margin: 16px 16px 0;
      border-bottom: 1px solid var(--v2-color-line);

      .name {
        font-size: var(--semi-font-size-large);
        color: var(--v2-color-grey700);
        word-break: break-all;
      }

      .description {
        margin-top: 6px;
        font-size: var(--semi-font-size-small);
        color: #A4AAB0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        .link-more {
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;

          .semi-typography-link-text {
            color: #69A1FF;
          }
        }
      }

      .blockchains {
        margin-top: 16px;
        margin-bottom: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
    }


    .btn-preview {
      width: initial;
      height: initial;
      margin: 8px 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px;
      border: 1px solid var(--v2-color-line);
      padding: 4px 12px;
      font-size: var(--semi-font-size-small);
      line-height: var(--semi-font-size-large);

      .semi-button-content-right {
        margin-left: 3px;
      }
    }
  }
}
