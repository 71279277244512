.job-list-container {
  .table-warpper {
    margin-top: 24px;
    margin-inline: 32px;

    .semi-table-container {
      background-color: #fff;

      .semi-table-body {
        overflow-y: hidden;
      }

      .semi-table-row:hover {
        .semi-table-row-cell::after {
          border-right: 1px solid #e3e7ec;
        }
      }
    }

    & .dataset {
      display: flex;
      align-items: center;
      gap: 8px;

      & .dataset-icon {
        width: 18px;
      }
    }

    & .integration {
      display: flex;
      align-items: center;
      gap: 8px;

      & .integration-icon {
        border: 1px solid #e3e7ec;
        border-radius: 4px;
        width: 24px;
      }
    }

    & .status-render-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .icon-wrapper {
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .progress-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;

        .progress {
          width: 100%;
          height: 6px;
          border-radius: 0px;

          &::-webkit-progress-bar {
            background-color: #e3e7ec;
          }

          &.in-sync::-webkit-progress-value {
            background-color: #3089D2;
            transition: width 0.5s ease;
          }

          &.sync-succeed::-webkit-progress-value {
            background-color: #5DBD5B;
          }
        }

        .sync-failed-count {
          position: absolute;
          left: 0;
          top: 10px;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          color: #8A4B00;
        }
      }

      .percentage-wrapper {
        display: flex;
        align-items: center;
        justify-content: end;

        width: 38px;
        height: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6;
      }
    }

    & .switch {
      width: 32px;
      height: 16px;
      vertical-align: sub;

      & .semi-switch-knob {
        width: 10px;
        height: 10px;
      }

      .semi-switch-loading-spin {
        width: 10px;
        height: 10px;
      }
    }

    & .setting-button {
      width: 36px;
      height: 36px;
      vertical-align: sub;
      padding: 10px;
      border-radius: 4px;

      &:hover {
        background-color: #f6f8fa;
      }
    }
  }

  .pagination-warpper {
    margin-top: 16px;
    margin-left: 33px;
    margin-right: 31px;

    display: flex;
    justify-content: space-between;

    & .job-count {
      &-text {
        font-weight: 400;
        font-size: 12px;
      }

      &-amount {
        font-weight: 800;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }

  .pagination-warpper.sync-pagination-warpper {
    .semi-page-item-active {
      color: #191e21;
      background-color: #edf1f4;
    }
  }
}
