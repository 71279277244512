.job-detail-configuration-container {
  .semi-form.semi-form-vertical {
    .semi-card.sync-frequency-card {
      margin-top: 0;
    }
  }
  .bottom-button-group {
    margin-top: 32px;

    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .right {
      display: flex;
      gap: 8px;
    }

    .semi-button {
      width: 120px;
      height: 36px;

      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      &.semi-button-light {
        border: 1px solid #e3e7ec;
      }

      &.semi-button-disabled {
        // default theme is solid
        background-color: #d1d7dd;
        color: white;

        &.semi-button-light {
          border: 1px solid #edf1f4;
          color: #edf1f4;
          background-color: #fafbfc;
        }
      }
    }
  }
}

.job-detail-modal-cancel-button {
  border: none !important;
}

.job-detail-modal-button {
  font-weight: 500;
  font-size: 14px;
}