.sync-jobs-step-indicator {
  display: flex;
  gap: 98px;
  background-color: #ffffff;
  padding: 15px 32px 27px 32px;
  border-bottom: 1px solid #e3e7ec;

  .sync-jobs-new-header-step {
    display: flex;
    gap: 8px;
    align-items: start;

    .step-done {
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      background-color: #e0f2ff;
      border: 2px solid #3089d2;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .step-number {
      width: 24px;
      height: 24px;

      box-sizing: border-box;
      border-radius: 100%;
      border: 2px solid;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 600;
      font-size: 14px;

      &.actived {
        background-color: #3089d2;
        color: white;
        border-color: #3089d2;
      }

      &.unactived {
        background-color: white;
        color: #d1d7dd;
        border-color: #e3e7ec;
      }
    }

    .step-text {
      position: relative;

      .step-title {
        margin-top: 1px;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */

        &.actived {
          color: black;
        }

        &.unactived {
          color: #a1aab0;
        }
      }

      .step-notice {
        position: absolute;
        top: 20px;
        left: 0px;

        width: 200px;

        color: #a1aab0;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 16.8px */
      }
    }

    &:not(:last-child) {
      .step-title::after {
        content: '';
        border: 0.5px solid #e3e7ec;
        top: 12px;
        position: absolute;

        width: 66px;
        margin-left: 16px;
      }
    }
  }
}

.sync-new-job {
  display: flex;
  flex-direction: column;

  .sync-new-job-main {
    background-color: #f6f8fa;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    padding-inline: 32px;
    padding-bottom: 32px;

    &.lock {
      overflow-y: hidden;
    }
  }

  .main-title {
    padding-block: 24px;
    font-size: 20px;
    font-weight: 600;
  }

  .bottom-buttons {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;

    .semi-button {
      height: 36px;
      padding: 7px 16px;

      .semi-button-content {
        display: flex;
        align-items: center;
        gap: 8px;

        font-weight: 500;
        font-size: 14px;

        .button-content-icon {
          width: 16px;
          height: 16px;
        }
      }

      &.back-button {
        background-color: #fafbfc;
        border: 1px solid #e3e7ec;
      }
    }
  }
}

.semi-input-wrapper-error {
  border: 1px solid #f79690;
  &:hover,
  &:active {
    border: 1px solid #ed6c64;
  }
}

.loading-mask {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  background-color: black;
  z-index: 1000;
  opacity: 0.3;
}
