

.activation-status {
  .content {
    width: var(--input-width);

    .title {
      color: var(--success-color);
    }

    .description {
      margin-top: 18px;
      font-size: 14px;
      color: var(--description-text-color);

    }
  }
}
