.chainbase-switch {
  width: calc(32px * var(--chainbase-switch-scale, 1));
  height: calc(16px * var(--chainbase-switch-scale, 1));

  &.semi-switch-checked {
    background-color: #3089d2;

    .semi-switch-knob, .semi-switch-loading-spin {
      transform: translateX(calc(18px * var(--chainbase-switch-scale, 1)));
      transition: transform 200ms ease-in-out, width 200ms ease-in-out;
      box-shadow: none;
    }

    &:active {
      .semi-switch-knob {
        transform: translateX(calc(9px * var(--chainbase-switch-scale, 1)));
      }
    }
  }

  .semi-switch-knob, .semi-switch-loading-spin {
    width: calc(10.67px * var(--chainbase-switch-scale, 1));
    height: calc(10.67px * var(--chainbase-switch-scale, 1));
  }

  &.chainbase-switch-disabled {
    background-color: rgba(48, 137, 210, 0.5);
  }
}
