@import "src/styles/minix";

.input-label {
  .icon-required {
    @include font(500);
    color: var(--semi-color-danger);
  }
}

.label-copyable {
  margin-top: 28px;

  .text-copyable {
    margin-top: 11px;
  }
}
