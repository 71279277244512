.sync-frequency-form-card.semi-card {
  margin-top: 24px;
  border-radius: 8px;

  .semi-card-body {
    padding: 24px;

    .top-zone {
      display: flex;
      gap: 16px;

      .semi-form-field {
        padding-top: 0;
        width: 50%;
        .semi-select {
          width: 100%;
          .semi-select-selection {
            justify-content: start;
          }
        }
      }
    }

    .bottom-zone {
      margin-top: 24px;

      .schedule-time-container {
        .schedule-time-content {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-top: 5px;
          font-weight: 400;
          font-size: 14px;

          .left {
            color: #191e21;

            .semi-select {
              margin-inline: 8px;
            }
          }

          .right {
            color: #a1aab0;
          }
        }

        .schedule-time-notice {
          margin-top: 24px;
          background-color: #edf1f4;
          border-radius: 4px;
          padding: 16px 12px;

          h3 {
            font-weight: 700;
            font-size: 12px;
            line-height: 16.8px;
            color: #444d56;
          }

          p {
            color: #76808a;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;

            .bold {
              font-weight: 700;
            }
          }
        }
      }

      .filter-container {
        .title-notice {
          margin-top: 4px;
          font-weight: 400;
          font-size: 12px;
        }

        .filter-tags {
          margin-top: 16px;
          display: flex;
          gap: 8px;

          .filter-tag {
            height: 32px;
            margin-right: 8px;
            background-color: #fafbfc;
            border: 1px solid #e3e7ec;

            &:hover {
              background-color: #f6f8fa;
              border: 1px solid #d1d7dd;
            }

            .semi-icon {
              padding: 1.75px;
            }

            .semi-icon:hover {
              background-color: #edf1f4;
              border-radius: 100%;
            }
          }

          .filter-add-filter-button {
            margin-top: 0;
            padding: 7px 14px;

            border-radius: 4px;
            border: 1px dashed var(--grey-grey-400, #d1d7dd);
            background: var(--grey-grey-100, #f6f8fa);

            .semi-button-content {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
