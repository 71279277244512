
.reset-password {

  .card {
    padding-bottom: 32px;
  }

  .input-control {
    margin-top: 0;
  }
  .title {
    width: var(--input-width);
    color: var(--primary-text-color);
    font-size: 16px;
  }

  .not-email {
    font-size: 12px;
    color: var(--description-text-color);
  }

  .btn-resend {
    font-size: 12px;
    color: var(--semi-color-link);
    cursor: pointer;

    &.disabled {
      color: var(--description-text-color);
    }
  }

  .code-error {
    display: none;
    margin-top: 53px;
    margin-bottom: 53px;
    width: var(--login-card-width);

    &.show {
      display: block;
    }

    .title {
      color: #FF3B3B;
      font-size: 16px;
      margin-bottom: 27px;
    }

    .notes {
      color: var(--description-text-color);
      font-size: 14px;

      p {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
