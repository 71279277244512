.integrations-table {
  background-color: #ffffff;

  .semi-table-row-head {
    .semi-table-row {
      cursor: pointer;
    }
  }
  
  .semi-table-row:hover {
 
    .semi-table-row-cell {
      background-color: #eaf6ff;
    }
  }

  .integration-type {
    width: 24px;
    height: 24px;
    border: 1px solid var(--v2-color-grey300);
    border-radius: var(--semi-border-radius-medium);
    overflow: hidden;
    margin-right: 8px;
  }

  .semi-table-row-head,
  .semi-table-row-cell {
    position: relative;
    &:after {
      position: absolute;
      content: ' ';
      display: block;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50%;
      border-right: 1px solid #edf1f4;
    }
  }

  .semi-table-row:hover {
    .semi-table-row-cell::after {
      border-right: 1px solid #e3e7ec;
    }
  }

  .semi-table-thead,
  .semi-table-row {
    :last-child:after {
      display: none;
    }
  }

  .semi-table-row-head {
    &:after {
      border-color: #e3e7ec;
    }
  }

  .datasets {
    color: var(--v2-color-grey500);
    display: inline-block;
    overflow: hidden;
    height: 20px;

    .count {
      background-color: var(--v2-color-grey300);
      padding: 0 7px;
      line-height: 20px;
      border-radius: 54px;
      margin-right: 8px;
    }
  }

  .status-tag {
    min-width: 57px;

    .semi-tag-content {
      justify-content: flex-start;
    }

    &.progressing .icon {
      animation: spin 1s linear infinite;
    }
  }
}

.pagination-wrapper {
  margin-top: 16px;

  .semi-page-item.semi-page-item-active {
    background-color: #edf1f4;
    color: #191e21;
  }

  .total {
    color: #191e21;

    .total-p {
      font-size: 12px;
    }
  }
}
