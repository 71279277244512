

.file-explorer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .search-input-wrapper {
    position: relative;
    margin: 0 24px;

    .semi-input-wrapper {
      border: 1px solid #E3E7EC;
      background: linear-gradient(0deg, #FAFBFC, #FAFBFC), linear-gradient(0deg, #E3E7EC, #E3E7EC);
      transition: all 0.25s;

      &:focus-within {
        box-shadow: 0 2px 4px 0 #00000026;
      }
    }

    .semi-autocomplete-option-list {
      min-width: 100px !important;
    }
  }

  .file-tree-wrapper {
    height: 100%;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
  }


  .semi-collapse {
    padding: 0 12px;


    &.filtered {
      .recent.semi-collapse-item, .semi-collapse-header {
        display: none;
      }
    }
    .semi-collapse-item {
      margin: 8px 0;
      border-bottom: 0;

      .semi-collapse-header {
        margin: 0 0 0 12px;
        height: 33px;
        padding: 0 12px 0 0;
        box-sizing: border-box;
        border-bottom: 1px solid #E3E7EC;
        border-radius: 0;

        &:hover {
          background: transparent;
        }
      }

      .semi-collapse-content {
        padding: 0;
      }
    }
  }
}