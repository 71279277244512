.sync-new-job-configure-job {
  margin-bottom: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .form-field-card {
    padding: 24px;
    margin-top: 16px;
    border-radius: 8px;

    .semi-card-body {
      padding: 0;

      .semi-form-field {
        padding: 0;
        width: calc(50% - 8px);
      }
    }

    .semi-form-field .semi-form-field-label {
      margin-bottom: 5px;
    }

    &.filters-card {
      .filter-add-filter-button {
        margin-top: 16px;
        padding: 7px 14px;

        border-radius: 4px;
        border: 1px dashed var(--grey-grey-400, #d1d7dd);
        background: var(--grey-grey-100, #f6f8fa);

        .semi-button-content {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }

    .title-notice {
      margin-top: 4px;

      color: #a1aab0;

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16.8px;
    }
  }
}

.job-creation-add-filter-modal-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;

  .semi-form-field-error-message {
    position: absolute;
    font-size: 12px;
  }

  .semi-select-selection {
    justify-content: start;
  }

  .semi-form-field {
    width: calc(33% - 4px);

    .semi-select {
      width: 100%;
      height: 36px;
    }

    .semi-input-wrapper {
      height: 36px;
      .semi-input {
        height: 36px;
      }
    }
  }
}

.filter-tag {
  height: 32px;
  margin-right: 8px;
  background-color: #fafbfc;
  border: 1px solid #e3e7ec;

  &:hover {
    background-color: #f6f8fa;
    border: 1px solid #d1d7dd;
  }

  .semi-icon {
    padding: 1.75px;
  }

  .semi-icon:hover {
    background-color: #edf1f4;
    border-radius: 100%;
  }
}
