@import "src/styles/minix";

.delete-popconfirm-wrapper {
  position: relative;
}

.delete-popconfirm-button {
  &.semi-button-danger {
    width: 132px;
    text-transform: uppercase;
  }
}

.delete-popover {
  box-shadow: 0 8px 31px 0 rgba(0, 0, 0, 0.05);
  padding: 0;
}


.btn-search {
  .icon-search {
    width: 16px;
    height: 16px;
  }
}
