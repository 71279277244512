
.text-copyable {
  width: 100%;
  background-color: var(--container-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--semi-input-border-radius);
  padding-left: 19px;
  box-sizing: border-box;
  height: var(--input-height);

  .semi-typography {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #787878;
    font-size: var(--semi-font-size-small);
  }

  .operate-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .btn-secrecy {
    display: flex;
    padding: 6px 0;

    .icon {
      width: 15px;
      height: 15px;
      padding: 3px 9px;
      border-right: 1px solid #EEEEEE;
    }
  }
}


.text-link {
  .semi-typography-link {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
  }
}
