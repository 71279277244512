.loading {
  border: 2px solid rgba(255, 255, 255, 0.13);
  border-top-color: #FFF;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  animation: spin 1s linear infinite;

  &.centered {
    margin: auto;
  }
}
