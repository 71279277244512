
.composer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  .btn-document {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .composer-executor {
    height: 100%;
    padding: 0;
    overflow: hidden;


    > .semi-row-flex {
      height: 100%;
    }

    .response-executor {
      border-left: 1px solid var(--semi-color-fill-0);
      height: 100%;

      .semi-form {
        height: 100%;
        display: flex;

        .form-ace-editor {
          padding-bottom: 0;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;

        .input-label {
          padding-top: 14px;
          margin-bottom: 11px;
        }
      }

      .json-view {
        padding: 19px;
        background-color: var(--container-background-color);
        border-radius: var(--semi-border-radius-small);

        flex: 1;
        overflow-y: scroll;
      }
    }

    .semi-typography-h2 {
      margin: 32px 32px 12px;
    }

    .content-wrapper {
      padding: 0 32px 32px;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: 100%;

      .semi-form {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        min-height: 100%;
      }
    }

    .request-executor {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;


      .executor-button {
        margin-top: auto;
        padding-top: 42px;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;

        .btn-send {
          width: 250px;
        }

        .btn-reset {
          background-color: transparent;
          margin-right: 19px;
        }
      }
    }
  }

  .api-document {
    .icon {
      margin-right: 16px;

      svg {
        font-size: 20px;
      }
    }
  }
}
