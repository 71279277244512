.sync-table-container {
  background-color: white;

  & .semi-table-row-head {
    position: relative;
  }

  & .semi-table-row-head::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 8px);
    height: 16px;
    width: 1px;
    border-right: 1px solid #e3e7ec;
  }

  & .semi-table-row-cell {
    position: relative;
  }

  & .semi-table-row-cell::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    height: 24px;
    width: 0;
    border-right: 1px solid #e3e7ec;
  }

  & .semi-table-row-cell:last-child::after,
  & .semi-table-row-head:last-child::after {
    display: none;
  }

  .semi-table-tbody .semi-table-row:hover {
    .semi-table-row-cell {
      background-color: #eaf6ff;
    }
  }

  &.clickable {
    .semi-table-tbody {
      .semi-table-row {
        cursor: pointer;
      }
    }
  }
}
