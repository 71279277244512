.chainbase-table {
  .semi-table-container {
    border-radius: 4px;
    border-bottom: 1px solid var(--semi-color-border);
    overflow: hidden;
  }
  &.semi-table-wrapper .semi-table-bordered .semi-table-thead > .semi-table-row {
    & .semi-table-row-head:first-child {
      border-top-left-radius: 3px;
    }
    & .semi-table-row-head:last-child {
      border-top-right-radius: 3px;
    }
  }

  &.semi-table-wrapper .semi-table-bordered .semi-table-tbody > .semi-table-row:last-child {
    & .semi-table-row-cell {
      border-bottom: none;
    }
    & .semi-table-row-cell:first-child {
      border-bottom-left-radius: 4px;
    }
    & .semi-table-row-cell:last-child {
      border-bottom-right-radius: 4px;
    }
  }

  & .semi-table-bordered .semi-table-placeholder {
    border: none;
  }
}


.table-empty-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    width: 40px;
  }

  .title {
    margin-top: 16px;
    font-size: var(--semi-font-size-large);
    line-height: 20px;
    color: var(--v2-color-grey600);
  }

  .desc {
    margin-top: 6px;
    font-size: var(--semi-font-size-small);
    line-height: var(--semi-font-size-regular);
    color: var(--v2-color-grey400);
  }
}


.query-result-error {
  padding-left: 28px;
  padding-right: 37px;
  background-color: var(--semi-color-light-danger);

  .icon {
    width: 15px;
  }

  .semi-banner-content {
    align-items: center;
    justify-content: flex-start;
  }

  .semi-banner-content-wrapper .semi-banner-content {
    justify-content: flex-start;
  }

  .semi-banner-close {
    &:active, &:hover {
      background-color: transparent;
    }

    .icon {
      svg path {
        stroke: var(--semi-color-danger);
      }
    }
  }
}
