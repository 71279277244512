.alter {
  display: inline-flex;
  align-items: center;


  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &.danger {
    color: var(--semi-color-danger);
  }

  &.tertiary {
    color: var(--v2-color-grey400);
  }

}
