.search-wrapper {
  padding: 0 24px;

  .semi-form-field {
    padding-right: 0;
    margin-right: 0;
  }


  .semi-form-field-main .select-chain {
    flex: 0.41;
    min-width: 138px;

    &.semi-select {
      width: 100%;
      height: var(--input-height-small);
      border: 1px solid #E3E7EC;
      background: linear-gradient(0deg, #FAFBFC, #FAFBFC), linear-gradient(0deg, #E3E7EC, #E3E7EC);

      .semi-select-selection {
        margin-left: 8px;
        justify-content: flex-start;
        border-right: 1px solid #E3E7EC;
      }
    }
  }

  .semi-form-field.input-table-name {
    margin-left: 8px;
    margin-right: 0;
    display: flex;
    flex: 1;

    .semi-input-wrapper {
      width: 100%;
      padding-left: 10px;
      height: var(--input-height-small);
      font-size: var(--semi-font-size-small);
      border: 1px solid #D1D7DD;
      background: linear-gradient(0deg, #FAFBFC, #FAFBFC), linear-gradient(0deg, #E3E7EC, #E3E7EC);
      transition: all 300ms;

      &:hover, &:focus-within {
        border: 1px solid #D1D7DD !important;
        background: #FFF !important;
      }

      &:focus-within {
        box-shadow: 0 2px 4px 0 #00000026;
      }


      .semi-input-default {
        padding-left: 0;
      }

      input::placeholder {
        font-size: var(--semi-font-size-small);
      }

    }
  }
}

.chain-list {

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--semi-font-size-small);

    .icon {
      width: 18px;
      object-fit: cover;
      margin-right: 8px;
    }
  }

  .semi-select-option-list {
    width: 100%;
  }

  .semi-select-option-list::-webkit-scrollbar {
    display: none;
  }
}

.block.table-navigation {
  .semi-portal-inner {
    width: calc(100% - 48px);
  }
}
