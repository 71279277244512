@import "src/styles/minix";

.file-tree.semi-tree-wrapper {
  .semi-tree-option-list {
    .semi-tree-option {
      .btn-more {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        box-sizing: border-box;
        padding: 0px;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.2s;
        border-radius: 4px;

        .semi-button-content {
          border-radius: 4px;
          background-color: #EDF1F4;
          fill: #2E363A;
        }

        .semi-button-content:active {
          background-color: #3089D2;
          fill: white;
        }
      }

      .btn-more.active {
        opacity: 1;
      }

      &:hover {
        background: #F6F8FA !important;

        .btn-more {
          opacity: 1;
        }
      }

      &:has(.active) {
        background-color: #F6F8FA !important;
      }

      &.semi-tree-option-selected {
        background: inherit;

        &:focus-within {
          background-color: #F6F8FA;
        }

        .semi-input-wrapper {
          border: 1px solid #3089D2;
          background: #FFFFFF;
        }
      }
    }
  }
}

.semi-portal {
  .query-item-dropdown {
    &.semi-dropdown-wrapper {
      border: 1px solid #E3E7EC;
      box-shadow: 0 4px 10px 0 #08111714;

      .semi-dropdown {
        .semi-dropdown-menu {
          .semi-dropdown-item {
            border: 0;
            height: 32px;
            min-width: 159px;
            box-sizing: border-box;
            font-size: 12px;
            @include font(400);
            line-height: 17px;
            color: #444D56;

            &:hover {
              background-color: #F6F8FA;
              color: #444D56;
            }
          }
        }
      }
    }
  }
}