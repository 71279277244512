@import "src/styles/minix";

.query-result-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;

  .query-result-header {
    padding: 11px 24px 11px 28px;
    display: flex;
    align-items: center;
    flex-direction: row;

    justify-content: space-between;

    .title {
      font-size: var(--normal-font-size);
    }

    .operate-group {
      .semi-button {
        height: 34px;
        padding: 10px 19px;
        @include font(500);
      }

      .btn-cancel {
        margin-left: 11px;
        min-width: 101px;
        background-color: var(--container-background-color);
        color: var(--primary-text-color);
        font-weight: 500;
      }

      .btn-run {
        margin-left: 11px;
        min-width: 101px;
        padding: 10px;
        font-size: var(--semi-font-size-small);

        &:not(:disabled) {
          background: linear-gradient(221deg, #434E5D 0%, #2F3846 100%);
        }

        &:not(:disabled):hover {
          background: var(--semi-color-primary-hover);
        }
      }

      .btn-generate {
        //border-color: var(--semi-color-border);
        border-color: var(--v2-color-grey300);
        color: var(--primary-text-color);
        font-size: var(--semi-font-size-small);

        .icon {
          width: 13px;
        }

        &:disabled {
          border-color: var(--container-background-color);
          color: var(--semi-color-focus-border);

          .icon {
            svg rect,
            svg path {
              stroke: var(--semi-color-focus-border);
            }
          }
        }

        &:not(:disabled):hover {
          border-color: var(--semi-color-link);
          color: var(--semi-color-link);

          .icon {
            svg rect,
            svg path {
              stroke: var(--semi-color-link);
            }
          }
        }
      }
    }
  }

  .query-result-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .auto-wrapper {
      width: 100%;
    }


    .query-result-empty {
      display: flex;
      margin: auto;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .icon {
        width: 123px;
      }

      .desc {
        margin-top: 29px;
        font-size: var(--semi-font-size-small);
        color: var(--primary-text-color);
      }

      .tip {
        margin-top: 10px;
        font-size: var(--semi-font-size-extra-small);
        color: var(--description-text-color);
        white-space: break-spaces;
      }
    }

    .chainbase-table {
      height: 100%;
    }

    .result-wrapper {
      //width: 100%;
      height: 100%;
      overflow-y: scroll;
    }


    .table-pagination {
      padding: 8px 28px 29px 31px;

      .select {
        min-width: 46px;
        margin: 0 8px;
        background: var(--terminal-background-color);
        border: 1px solid var(--semi-color-focus-border);
        height: 23px;

        .semi-select-arrow {
          transform: scale(0.75);
        }
      }

      .semi-col > span {
        @include font(500);
        color: var(--description-text-color);
      }

      .pager {
        display: inline-flex;
        margin-left: 8px;

        .btn {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          background-color: var(--container-background-color);

          &.btn-next {
            transform: rotate(180deg);
          }

          &:disabled {
            .icon {
              svg path {
                fill: var(--disabled-color);
              }
            }
          }

          .icon {
            width: 4px;
          }
        }
      }
    }
  }

  .query-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 19px;


    > span {
      @include font(500);
      padding: 0 8px;
      font-size: var(--semi-font-size-extra-small);
      border-right: 1px solid var(--second-description-color);
    }

    .latency {
      color: var(--description-text-color);
    }

    .icon-success {
      width: 13px;
    }

    .btn-download {
      .icon {
        width: 11px;
      }

      &:hover {
        background-color: transparent;
      }
    }

  }
}


.semi-portal {
  .table-pagination-dropdown {
    margin-bottom: -20px;
  }
}
