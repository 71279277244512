.navigationBanner {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px !important;
  padding: 0 51px;
  color: #FFF;
  font-size: 12px;
  line-height: 15px;
  box-sizing: border-box;
  background-color: #00110E;

  a {
    color: #2188e9;

    &:hover {
      color: #005aaf;
    }
  }

  .signUp {
    :global span {
      text-decoration: underline;
      margin-left: 16px;
    }
  }

  .languageButton {
    background-color: transparent;
    border: 0;
    color: #FFF;
    cursor: pointer;
    margin-left: 20px;

    .languageButtonIcon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      display: flex;
      align-items: center;
    }
  }
}