
.dashboard-container {
  background-color: var(--v2-color-grey200);

  .header {
    .filter-group {
      .semi-select {
        min-width: 160px;

        .semi-select-selection {
          justify-content: flex-start;
        }

        .semi-select-prefix {
          padding-left: 12px;
          padding-right: 8px;
        }
      }
    }
  }

  .dashboard-info {
    height: 320px;

    > .semi-col > .semi-row-flex {
      height: 100%;
    }

    .block.data-item {
      box-sizing: border-box;
      height: 100%;
      padding: 35px 32px 0;
      box-sizing: border-box;
      border-bottom: 1px solid var(--v2-color-line);
      border-right: 1px solid var(--v2-color-line);
      display: flex;
      flex-direction: column;

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .title {
        color: var(--v2-color-grey700);
        display: inline-flex;
        align-items: center;
        line-height: 20px;
      }

      .data-value {
        margin-top: 22px;

        .semi-typography {
          color: var(--v2-color-grey700);
          font-size: 28px;
          line-height: 33px;
          letter-spacing: -0.019em;
        }
      }

      .time {
        color: var(--v2-color-grey400);
        text-transform: uppercase;
      }
    }

  }

  .request-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-bottom: 1px solid var(--v2-color-line);
    box-sizing: border-box;
    padding: 30px;

    .request-chart {
      margin-top: 26px;
      width: 100%;
      flex: 1;
    }

    .title {
      color: var(--v2-color-grey700);
    }

    .filter-group {
      margin-top: auto;

      .filter-item {
        --filter-item-active-color: var(--container-background-color);

        font-size: var(--semi-font-size-small);
        line-height: var(--semi-font-size-large);
        height: var(--height-control-small);
        padding: 0 12px 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--v2-color-line);
        box-sizing: border-box;
        color: var(--v2-color-grey400);
        transition: all 0.35s;
        cursor: pointer;
        border-radius: var(--semi-border-radius-medium);

        .circle {
          width: 6px;
          height: 6px;
          margin-right: 8px;
          border-radius: 50%;
          background-color: var(--filter-item-active-color);
        }

        &:hover {
          background-color: var(--container-background-color);
        }

        &.active {
          border: 1px solid var(--v2-color-line);
          color: var(--v2-color-grey700);
          background-color: var(--v2-color-grey50);
        }

        &.primary {
          --filter-item-active-color: var(--semi-color-text-0);
        }

        &.assist {
          --filter-item-active-color: var(--semi-color-assist);
        }

        &.info {
          --filter-item-active-color: var(--semi-color-info);
        }

        &.success {
          --filter-item-active-color: var(--semi-color-success);
        }

        &.danger {
          --filter-item-active-color: var(--semi-color-danger);
        }

        &.warning {
          --filter-item-active-color: var(--semi-color-warning);
        }
      }
    }
  }

  .daily-request {
    display: flex;
    flex-direction: column;

    .echart-map {
      flex: 1;
      margin-top: 26px;
      border: 1px solid #F7F7F7;
      border-radius: var(--semi-input-border-radius);
      position: relative;

      .scale-wrapper {
        position: absolute;
        left: 28px;
        bottom: 28px;
        display: flex;
        flex-direction: column;

        .btn {
          cursor: pointer;
          font-size: 10px;
          width: 23px;
          height: 23px;
          border-radius: var(--semi-border-radius-extra-small);
          background-color: var(--container-background-color);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 9px;

          transition: background-color 0.25s ease-in-out;

          &:hover {
            background-color: var(--semi-link-button-fill);
          }
        }
      }
    }
  }

  .request-block .loading,
  .daily-request .loading {
    margin: auto;
  }


  .projects {
    margin-top: 16px;
    padding-bottom: 36px;
    background-color: var(--v2-background-color-light);

    .header {
      border-top: 1px solid var(--v2-color-line);
      border-bottom: 0;
      height: initial;
      padding: 16px 32px;
      border-bottom: 1px solid #FFF;
      z-index: 100;
    }

    .project-list {
      padding: 0 32px;
      .semi-table-thead {
        height: 48px;
      }

      .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
        padding: 20px 16px;
      }

      .semi-table-tbody > .semi-table-row {
        cursor: pointer;
      }

      .hover-icon {
        display: none;
      }
      .semi-table-row:hover .hover-icon {
        display: inline-flex;
      }

      .pagination {
        margin-top: 16px;
      }

      .empty {
        height: 350px;

        .icon {
          width: 100px;
          height: 100px;
        }

        .title {
          margin-top: 16px;
          font-size: var(--semi-font-size-large);
          line-height: 19px;
          color: var(--v2-color-grey600);
        }

        .desc {
          margin-top: 4px;
          color: var(--v2-color-grey400);
          font-size: var(--semi-font-size-small);
          line-height: var(--semi-font-size-regular);
        }
      }

      .min-w-400 {
        min-width: 400px;
      }
      .min-w-186 {
        min-width: 186px;
      }
      .min-w-294 {
        min-width: 294px;
      }
      .min-w-150 {
        min-width: 150px;
      }
      .min-w-50 {
        min-width: 50px;
      }
    }
  }
}


.create-project-popover {
  .create-project-wrapper {
    .tip {
      margin-top: 32px;
      color: var(--v2-color-grey700);
      margin-bottom: 12px;
    }
  }
}
