.sync-common-notice-label {
  display: flex;
  align-items: center;
  gap: 4px;

  .label-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    color: #191e21;
  }
}