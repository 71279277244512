.activate-fields-card {
  padding: 24px;
  margin-top: 16px;
  border-radius: 8px;

  .semi-table-thead {
    font-size: 12px;
  }

  .semi-card-body {
    padding: 0;
    max-width: 520px;

    .semi-form-field {
      &.semi-form-field[x-label-pos='top'] {
        padding: 0;
      }
    }
  }

  .semi-form-field .semi-form-field-label {
    margin-bottom: 5px;
  }

  .left-to-right {
    --icon-scale: 1;
    --font-size: 16px;
    --font-weight: 600;
    --line-height: 22.4px;
    margin-block: 24px;
  }

  .table-name-input-wrapper {
    width: 50%;
    margin-bottom: 24px;

    .label-title {
      font-size: 14px;
    }
  }

  .semi-card-body {
    max-width: 100%;
  }

  .sync-table-container.table-wrapper {
    .table-header.sync-col {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .five-arrows-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .five-arrows {
        width: 25px;
        height: 12px;
      }
    }

    .table-row.sync-col {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
    }
  }

  .title-notice {
    margin-top: 4px;

    color: #a1aab0;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
  }

  .label-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 19.6px;
    color: #191e21;

    &.card-title {
      font-size: 14px;
    }
  }
}
