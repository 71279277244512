

.delete-popover {
  .delete-wrapper {
    padding-top: 32px;

    .tip {
      color: var(--v2-color-grey700);
      margin-bottom: 8px;
    }

    .undone-tip {
      margin-bottom: 34px;
    }
  }
}
