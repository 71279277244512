
.toast-container {
  position: fixed;
  z-index: 10001;
  bottom: 151px;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  align-items: center;
  flex-direction: column-reverse;


  .toast {
    position: relative;
    padding: 12px 62px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    margin-top: 20px;
    font-size: var(--semi-font-size-small);

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      display: flex;

      .icon {
        width: 20px;
        height: 20px;
      }
    }

    &.success {
      background-color: #F5FFF6;
      border-color: #B6E0BA;
      color: #27BC37;
      box-shadow: 0 10px 21px 0 rgba(39, 188, 55, 0.1);
    }

    &.error {
      background-color: #FFF0F0;
      color: #FF3B3B;
      border-color: #FFCCCC;
      box-shadow: 0 10px 21px 0 rgba(161, 77, 77, 0.1);
    }

  }
}
