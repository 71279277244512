@import 'font.scss';
@import 'minix.scss';
@import 'variable.scss';
@import 'animate.scss';
@import 'semi-extend.scss';
@import 'table.scss';
@import 'common.scss';

p,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a {
  color: var(--semi-color-link);
  text-decoration: blink;
  outline: none;
  transition: color 0.3s;

  &:hover {
    color: #2462ca;
  }

  &.underline {
    text-decoration: underline;
  }

  &.secondary {
    color: var(--semi-color-text-2);

    &:hover {
      opacity: 0.7;
    }
  }
}

button {
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--semi-font-size-regular);
  color: var(--primary-text-color);
}

p {
  font-size: var(--semi-font-size-regular);
  line-height: calc(var(--semi-font-size-regular) + 2px);
}

body {
  @include font(400);

  .regular {
    @include font(400);
  }

  .bolder {
    @include font(700);
  }

  .oblique {
    font-style: oblique;
  }

  .semi-bold {
    @include font(600);
  }

  .medium {
    @include font(500);
  }

  .heavy {
    @include font(900);
  }

  .DIN {
    font-family: 'DINAlternate-Bold', sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.hide {
  display: none !important;
}

.container {
  .btn-create {
    font-size: var(--normal-font-size);
    height: 41px;
    @include font(500);

    .icon {
      font-size: 16px;
      color: #fff;
    }
  }

  &.settings-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 32px;
    width: 1120px;
    margin: auto;
  }
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.text-capitalize {
  text-transform: capitalize;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.dynamic-svg-icon {
  > div {
    display: flex;
  }

  .full-svg {
    width: 100%;
    height: 100%;
  }
}

.position-relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-right {
  text-align: right;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

img {
  user-select: none;
}

.capitalize {
  text-transform: capitalize;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Roboto;
    src: url(/assets/fonts/Roboto-Flex.ttf) format('ttf');
  }
}
