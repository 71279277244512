.table-container {
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .empty-block {
    display: flex;
    flex: 1;
  }

  .total-count {
    margin-top: 24px;
  }

  .blockchain {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      width: 21px;
      height: 21px;
      margin-right: 8px;
    }
  }

  .action-group {
    margin-right: -14px;
  }

  .semi-table-thead > .semi-table-row > .semi-table-row-head.actions,
  .semi-table-tbody > .semi-table-row > .semi-table-row-cell.actions {
    padding-right: 28px;
  }

  .semi-table-tbody > .semi-table-row > .semi-table-row-cell {
    word-wrap: initial;
    word-break: keep-all;
  }
}
