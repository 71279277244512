
.quick-start-modal {
  .semi-modal.semi-modal-large {
    width: 932px;
    height: 452px;

    .semi-modal-body-wrapper {
      margin: 0;
    }

    .semi-modal-content {
      padding: 0;
      display: flex;
      flex: 1;
    }

    .quick-start-container {
      position: relative;

      .close-wrapper {
        position: absolute;
        z-index: 9;

        top: 30px;
        right: 30px;

        .btn-close {
          &:hover {
            outline: none;
            background-color: transparent;
          }
        }
      }

      .next-wrapper {
        position: absolute;
        z-index: 9;
        right: 30px;
        bottom: 39px;

        .btn-next {
          width: 121px;
          height: 45px;
          padding: 0;

          background: linear-gradient(221deg, #525E70 0%, #3C4757 100%);

          &.finish {
            color: #FFF;
            background: linear-gradient(221deg, #2F7AF7 0%, #2358AF 100%);
            box-shadow: 0 19px 19px 1px rgba(66, 78, 94, 0.12);

            &:hover {
              background: linear-gradient(221.38deg, #4383EF -26.8%, #3073E3 102.98%);
            }
          }

          .semi-button-content {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .icon {
            margin-left: 17px;
            width: 22px;
          }
        }
      }

      .semi-carousel {
        width: 932px;
        height: 452px;

        .semi-carousel-indicator.semi-carousel-indicator-dot {
          right: 256px;
          bottom: 57px;

          .semi-carousel-indicator-item {
            background-color: var(--second-description-color);

            &.semi-carousel-indicator-item-active {
              background-color: var(--semi-color-link);
            }
          }
        }
      }

      .carousel-item {
        width: 932px;
        height: 452px;
        display: flex;
        flex-direction: row;
        overflow-y: hidden;

        .video-container {
          width: 555px;
          flex-shrink: 0;
          height: 100%;
          background-color: #f5f4f6;

          video {
            pointer-events: none;
            width: 100%;
            height: 100%;
          }
        }

        .content-container {
          padding: 84px 38px 38px;

          .desc {
            margin-top: 24px;
            margin-bottom: 13px;
            font-size: var(--semi-font-size-small);
            color: var(--description-text-color);
          }

          .link-get-started {
            font-size: var(--semi-font-size-small);
          }
        }
      }

    }
  }

}
